import React, { useContext, useEffect } from 'react';
import { StyleSheet,Image,Dimensions,ScrollView } from 'react-native';

import Igraj from '../components/Igraj';
import { Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import { UserContext } from "../state/User"
import podpis from '../assets/images/podpis.png';
export default function OpisAplikacijeScreen({navigation,route}) {
   const colorScheme = useColorScheme();
   const user=null;
    const [ state, dispatch ] = React.useContext(UserContext);
    console.log("state");
   console.log(state);
      const screenWidth = Dimensions.get('window').width;
  return (
    <View style={styles.container}>
  <ScrollView contentContainerStyle={{
          justifyContent: 'center',
          alignContent: 'center',
        }}>
      <View style={styles.SectionStyle}>
      <Text style={styles.text}>
        Aplikacija uporabnikom omogoča dostop do dodatnih vsebin, ki so povezane z igriščem Gorana Dragića. Slednje so dosegljive z izbiro možnosti "TRENIRAJ!" ali "DRUŽABNE IGRE" na prvem zaslonu. Po izbiri, se bo pokazal seznam vsebin, uporabnik pa lahko preko aplikacije nato gleda vsebine.
      </Text>
      </View>
      <View style={styles.SectionStyle}>
      <Text style={styles.text}>
      Preko aplikacije lahko tudi spremljate ostale uporabnike igrišče, ko ti najavijo svoj prihod na igrišče. Pri izbiri možnosti "KDO IGRA?" na prvi strani se najprej pokaže izbira pametnih igrišč. Po izbiri igrišča se prikažejo vsi igralci, ki so trenutno najavljeni na igrišču. Z izbiro časa na vrhu zaslona, lahko določite ali želite videti uporabnike, ki bodo na igrišču aktivni danes ali celo jutri. Uporabniki se na igrišču pokažejo na različnih mestih, ki ponazarjajo njihov namen obiska igrišča, ob ikoni igralcev pa se izpiše tudi število igralcev v tej vlogi. Z izbiro ikone se izpiše seznam igralcev s podatki, ki jih slednji delijo.
      </Text>
      </View>
      <View style={styles.SectionStyle}>
      <Text style={styles.text}>
      Če uporabnik želi najaviti prihod na igiršče, lahko izbere možnost "NAJAVI SE" na dnu zaslona. Najprej je potrebno izbrati igrišče, ki ga uporabnik želi obiskati, nato pa je potrebno določiti čas in namen obiska igrišča. Če želi uporabnik z ostalimi deliti več podatkov, mora ustvariti svoj profil, v katerem navede podatke in izbere ali jih bo delil z drugimi.
      </Text>
    </View>
    <View style={styles.SectionStyle}>
      <Text style={styles.text}>
     Z izbiro opcije "PROFIL" na dnu zaslona, se uporabnik lahko registrira v aplikacijo ali se v njo prijavi, če je registracijo že opravil. Tukaj lahko tudi ureja svoje podatke in njihovo deljenje z drugimi uporabniki.
      </Text>
    </View>
    
    

</ScrollView>
    
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    
  },
  SectionStyle: {
    flexDirection: 'row',
    marginVertical: 5,
    marginLeft: 35,
    marginRight: 35,
    justifyContent:"space-between"

        
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  text:{
    marginTop:10,
    textAlign:"justify"
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
