import * as React from 'react';
import { StyleSheet,ScrollView,Dimensions } from 'react-native';

//import Igraj from '../components/Igraj';
import { Text, View } from '../components/Themed';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import { ListItem, Avatar } from 'react-native-elements'

import Constants from 'expo-constants';
const apiServerUrl=Constants.manifest.extra.apiServerUrl;

import placeholderKV from '../assets/images/treningImages/placeholderKV.jpg';
import placeholderAV from '../assets/images/treningImages/placeholderAV.jpg';
import placeholderKI from '../assets/images/treningImages/placeholderKI.jpg';

export default function TreningiScreen({ route,navigation }) {
   const colorScheme = useColorScheme();
   const list=route.params.list;
   const screenWidth = Dimensions.get('window').width;






   const prikaziVrstoVidea= (video) => {
   
   if(video.tip=="DI")
     return "Družabna igra";
   if(video.tip=="AV")
     return "Atletska vaja";
   if(video.tip=="POZ")
     return "Pozdrav";
    if(video.tip=="KV")
     return "Košarkarska vaja";
   
   return "";

   }
   
   const loadVideoScreen= (video) => {
    console.log(video);
    navigation.navigate('Video',{video:video});
   }
    const vrniAvatarImage= (video) => {
    

    switch (video.avatar) {
      /*case 1:
        return require('../assets/images/treningImages/1.png');
      case 2:
        return require('../assets/images/treningImages/2.jpg');
      case 3:
        return require('../assets/images/treningImages/3.jpg');*/
      default: {
          switch (video.tip) {
            case "KV":
              return placeholderKV;
            case "AV":
              return placeholderAV;
            case "DI":
              return placeholderKI;
            default:
            return placeholderKV;
  
      }
    }
    
   }
 }

//   <Avatar source={l.avatar?require(`../assets/images/treningImages/${l.avatar}.jpg`):require('../assets/images/treningImages/placeholder.jpg')} />
   
  return (
    <View style={styles.container}>
    <ScrollView style={styles.scroll}>
    
     {
    list.map((l, i) => (
      <ListItem containerStyle={{backgroundColor:Colors[colorScheme].tabBackground, borderBottomColor: 'white'}}
             key={i} bottomDivider
      onPress={() => {loadVideoScreen(l);}}
      >
     
        <Avatar  rounded source={vrniAvatarImage(l)} />
        <ListItem.Content >
          <ListItem.Title style={{ color: 'white', fontFamily: 'Exo_400Regular' }}>{l.naslov}</ListItem.Title>
            <ListItem.Subtitle style={{ color:  Colors[colorScheme].tabIconSelected}}>{prikaziVrstoVidea(l)}</ListItem.Subtitle>
        </ListItem.Content>
           <ListItem.Chevron  color="white" />
      </ListItem>
    ))
  }
  </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  scroll:{
width:Dimensions.get('window').width
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  backgroundVideo: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
});
