import * as React from 'react';
import {useState, createRef} from 'react';
import { StyleSheet,ScrollView,Dimensions } from 'react-native';

//import Igraj from '../components/Igraj';
import { Text, View } from '../components/Themed';
import AsyncStorage from '@react-native-async-storage/async-storage';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

import { ListItem, Avatar } from 'react-native-elements'

import Constants from 'expo-constants';
const apiServerUrl=Constants.manifest.extra.apiServerUrl;

export default function IgriscaNajavaScreen({ route,navigation }) {
   const colorScheme = useColorScheme();
      const [isLoading, setIsLoading] = useState(true);
      const [list, setList] = useState([]);



   const screenWidth = Dimensions.get('window').width;

   if(isLoading){

  fetch(apiServerUrl+'igrisce', {
      method: 'GET',
      headers: {
       Accept: 'application/json',
      'Content-Type': 'application/json'
 
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        //Hide Loader
        console.log(responseJson);
        // če prijava uspe, pelji tam kjer si prišel iz prijave ozirma na registracijske podatke zaenkrat
        if (responseJson.napaka) {
        } 
        else {
        console.log(    responseJson          );
       // navigation.navigate('Igrisca',{list:responseJson,title:"Pametna igrišča"});

       setList(responseJson);
       setIsLoading(false);

         
       
          
        }
      })
      .catch((error) => {
        //dodaj na treniraj seznam še error, če se ne naložijo videi!!!
        console.error(error);
      });
}
   
   const loadIgrisceScreen= (igrisce) => {
       navigation.navigate('Najava',{igrisce:igrisce,title:"Najava igranja/treniranja"});
     //      navigation.navigate('Najava',{igrisce:igrisce,title:"Najava igranja/treniranja",user:'{"niPrijavljen":true,"name":"Anonimnež"}'});
     
 
    
   }
    

//   <Avatar source={l.avatar?require(`../assets/images/treningImages/${l.avatar}.jpg`):require('../assets/images/treningImages/placeholder.jpg')} />
   
  return (
    <View style={styles.container}>
    <ScrollView style={styles.scroll}>
    
     {
       !isLoading&&
    list.map((l, i) => (
      <ListItem containerStyle={{backgroundColor:Colors[colorScheme].tabBackground, borderBottomColor: 'white'}}
             key={i} bottomDivider
      onPress={() => {loadIgrisceScreen(l);}}
      >
     
        <Avatar   overlayContainerStyle={{backgroundColor: 'white'}} rounded title={l.id} titleStyle={{color:Colors[colorScheme].tabIconSelected,fontFamily:"Exo_700Bold"}} />
        <ListItem.Content >
          <ListItem.Title style={{ color: 'white', fontFamily: 'Exo_400Regular' }}>{l.naziv}</ListItem.Title>
            <ListItem.Subtitle style={{ color:  Colors[colorScheme].tabIconSelected}}>{l.naslov}</ListItem.Subtitle>
        </ListItem.Content>
           <ListItem.Chevron  color="white" />
      </ListItem>
    ))
    }
  
  </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  scroll:{
width:Dimensions.get('window').width
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  backgroundVideo: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
});
