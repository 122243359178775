
import React from "react"
import { reducer} from "./reducer"

export const UserContext = React.createContext({
  state: null,
  dispatch: () => null
})

export const UserProvider = ({ initialState,children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  console.log(state);

  return (
    <UserContext.Provider value={[ state, dispatch ]}>
    	{ children }
    </UserContext.Provider>
  )
}