



import * as React from 'react';
import { StyleSheet,ScrollView,Dimensions } from 'react-native';

//import Igraj from '../components/Igraj';
import { Text, View } from '../components/Themed';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import { ListItem, Avatar } from 'react-native-elements'


import Constants from 'expo-constants';
const apiServerUrl=Constants.manifest.extra.apiServerUrl;

export default function IgriscaScreen({ route,navigation }) {
   const colorScheme = useColorScheme();
   const list=route.params.list;
   const screenWidth = Dimensions.get('window').width;
const [errortext, setErrortext] = React.useState('');
   
   
   const loadIgrisceScreen= (igrisce) => {
   

    fetch(`${apiServerUrl}igralec/${igrisce.id}/zdaj`, {
      method: 'GET',
      headers: {
       Accept: 'application/json',
      'Content-Type': 'application/json'
 
      },
    })
      .then((response) =>   {
        console.log(response);
          if(response.ok)
            return response.json();
          else 
            throw new Error(response.statusText);
        })
      .then((responseJson) => {
        //Hide Loader
        console.log(responseJson);
        // če prijava uspe, pelji tam kjer si prišel iz prijave ozirma na registracijske podatke zaenkrat
        if (responseJson.napaka) {
            setErrortext(responseJson.napaka);
        } 
        else {
        console.log(    responseJson          );
        navigation.navigate('Igralci',{list:responseJson,title:igrisce.naziv,igrisce:igrisce});
         
       
          
        }
      })
      .catch((error) => {
         setErrortext("Napaka pri pridobivanju podatkov!");
      });
   }
    

//   <Avatar source={l.avatar?require(`../assets/images/treningImages/${l.avatar}.jpg`):require('../assets/images/treningImages/placeholder.jpg')} />
   
  return (
    <View style={styles.container}>
     {(errortext) != '' ? (
                <View style={styles.SectionStyle}>
            <Text style={styles.errorTextStyle}>
              {errortext}
            </Text>

            </View>
          ) : null}
    <ScrollView style={styles.scroll}>
    
     {
    list.map((l, i) => (
      <ListItem containerStyle={{backgroundColor:Colors[colorScheme].tabBackground, borderBottomColor: 'white'}}
             key={i} bottomDivider
      onPress={() => {loadIgrisceScreen(l);} }
      >
     
        <Avatar   overlayContainerStyle={{backgroundColor: 'white'}} rounded title={l.id} titleStyle={{color:Colors[colorScheme].tabIconSelected,fontFamily:"Exo_700Bold"}} />
        <ListItem.Content >
          <ListItem.Title style={{ color: 'white', fontFamily: 'Exo_400Regular' }}>{l.naziv}</ListItem.Title>
            <ListItem.Subtitle style={{ color:  Colors[colorScheme].tabIconSelected}}>{l.naslov}</ListItem.Subtitle>
        </ListItem.Content>
           <ListItem.Chevron  color="white" />
      </ListItem>
    ))
  }
  </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
   SectionStyle: {
    flexDirection: 'row',
    flexGrow:1,
   
    marginTop: 10,
    marginLeft: 35,
    marginRight: 35,

        
  },
    errorTextStyle: {
    color: 'red',
    textAlign: 'center',
    fontSize: 14,
  },
  scroll:{
width:Dimensions.get('window').width
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  backgroundVideo: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
});
