const tintColorLight = '#cb1a2b';
const tintColorDark = '#cb1a2b';

export default {
  dark: {
    text: '#fff',
    background: '#1c2334',
    tabBackground: '#1c2334',
    tint: '#fff',
    tabIconDefault: '#ccc',
    tabIconSelected: '#cb1a2b',
  },
  light: {
    text: '#fff',
    background: '#1c2334',
    tabBackground: '#1c2334',
    tint: '#fff',
    tabIconDefault: '#ccc',
    tabIconSelected: '#cb1a2b',
  },
};
