
import { StyleSheet, TouchableOpacity,Dimensions  } from 'react-native';
import Colors from '../constants/Colors';
import { MonoText } from '../components/StyledText';
import { Text, View } from '../components/Themed';
import React, {useState, createRef,useEffect} from 'react';
import SwitchSelector from "react-native-switch-selector";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { UserContext } from "../state/User"
import Carousel from 'react-native-snap-carousel';
import  SwiperFlatList from 'react-native-swiper-flatlist';

import avatar1 from '../assets/images/avatars/1.png';
import avatar2 from '../assets/images/avatars/2.png';
import avatar3 from '../assets/images/avatars/3.png';
import avatar4 from '../assets/images/avatars/4.png';
import avatar5 from '../assets/images/avatars/5.png';
import avatar6 from '../assets/images/avatars/6.png';
import avatar7 from '../assets/images/avatars/7.png';
import avatar8 from '../assets/images/avatars/8.png';
import avatar9 from '../assets/images/avatars/9.png';


import Constants from 'expo-constants';
const apiServerUrl=Constants.manifest.extra.apiServerUrl;

import {
  ActivityIndicator,
  
Switch,
  TextInput,
  Image,
  KeyboardAvoidingView,
  Keyboard,
  ScrollView,
  Picker
} from 'react-native';


/*
poglej če je user_id v ls, če je, poglej, če so ostali podatki v ls 
naloži podatke in jih prepiši (če imaš net..., če ne vzami iz ls, če n v ls, izpiši napako)
Logout gumb!

ni user_id, pokaži prijavo, spodaj pa link, da če še nisi registriran, si ustvari račun
register, po register, dobiš nazaj user_id, shrani ga, in pokaži možnost urejanja profila
(spremeni geslo na mail??, kasneje)

po prijavi dodaj user_id, dobi podatke in prikaži urejanje profila

*/


export default function ProfilScreen({ route,navigation }) {

  
  
  const [ state, dispatch ] = React.useContext(UserContext);
  //const [ user, setUser ] = useState(state.user);
  const [userId, setUserId] = useState(state.user._id);
  const [userName, setUserName] = useState(state.user.name?state.user.name:"");
  const [userEmail, setUserEmail] = useState(state.user.email);

  const [userAvatar, setUserAvatar] = useState(state.user.avatar);
  const [userAvatarIndex, setUserAvatarIndex] = useState(parseInt(state.user.avatar)-1);
  const [userAge, setUserAge] = useState(state.user.yofb?state.user.yofb.toString():'');
  const [vrstaIgralca, setVrstaIgralca] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errortext, setErrortext] = useState('');
  //route.params.
  const [selectedValue, setSelectedValue] = useState(0);
   const [current, setCurrent] = useState(state.user.vrstaIgralca?state.user.vrstaIgralca:"Rekreativec");
  const [
    isRegistraionSuccess,
    setIsRegistraionSuccess
  ] = useState(false);

  const emailInputRef = createRef();
  const ageInputRef = createRef();
  const addressInputRef = createRef();
  const passwordInputRef = createRef();
   const [isImeEnabled, setIsImeEnabled] = useState(state.user.deliIme);
  const toggleImeSwitch = () => {setIsImeEnabled(previousState => !previousState);setIsRegistraionSuccess(false);}
   const [isYofbEnabled, setIsYofbEnabled] = useState(state.user.deliLetoRojstva);
  const toggleYofbSwitch = () => {setIsYofbEnabled(previousState => !previousState);setIsRegistraionSuccess(false);};
  const [isViEnabled, setViEnabled] = useState(state.user.deliVrstoIgralca);
  const toggleViSwitch = () => {setViEnabled(previousState => !previousState);setIsRegistraionSuccess(false);};

const [isAvatarEnabled, setAvatarEnabled] = useState(state.user.deliAvatar);
  const toggleAvatarSwitch = () => {setAvatarEnabled(previousState => !previousState);setIsRegistraionSuccess(false);};
const avatarData=[
{index:0,source:avatar1},
{index:1,source:avatar2},
{index:2,source:avatar3},
//{index:3,source:avatar3},
{index:3,source:avatar4},
{index:4,source:avatar5},
{index:5,source:avatar6},
{index:6,source:avatar7},
{index:7,source:avatar8},
{index:8,source:avatar9}

];
const carouselRef = React.useRef(null);

var renderAvatar = ({item}) => {
    console.log("Renderam");
    console.log(item);
 return (
         <TouchableOpacity
                 
                 style={(item.index==userAvatarIndex)?styles.selectedAvatarItem:styles.AvatarItem}
                 onPress={() => {
                   console.log(carouselRef);
                   console.log(item);
                  //carouselRef.current.scrollToIndex({index:item.index});
                  setUserAvatar((item.index+1).toString());
                  setUserAvatarIndex(item.index);
                  console.log((item.index+1).toString());
                 }}>
         
         <Image style={(item.index==userAvatarIndex)?{width:30,height:30}:{width:25,height:25}} source={item.source}/>

         </TouchableOpacity>
 );
}

useEffect(()=>{
  console.log("Scrollam");
  
  carouselRef.current.scrollToIndex({index:userAvatarIndex}); 

},[])



const getInitalIndex= () => {
  if(state.user.vrstaIgralca=="Rekreativec")
    return 0;
  if(state.user.vrstaIgralca=="Amater")
    return 1;
  if(state.user.vrstaIgralca=="Profesionalec")
    return 2;


}

//useEffect(() => {
  //setCurrent(getInitalIndex());
//}, [])

const handleOdjavaButton= () => {

  AsyncStorage.removeItem('@gd_user');
  dispatch({ type: "logoffUser" });
  navigation.reset({
  index: 0,
  routes: [{ name: 'Domov' }],
});

}
const handleSubmitButton = () =>{

 setErrortext('');
   console.log(state.user);
   /* if (!userName) {
      alert('Please fill Name');
      return;
    }
    if (!userEmail) {
      alert('Please fill Email');
      return;
    }
    if (!userAge) {
      alert('Please fill Age');
      return;
    }
   
    if (!userPassword) {
      alert('Please fill Password');
      return;
    }
*/
 if (!userAge.match(/^(19|20)\d{2}$/)&&userAge) {
   setErrortext('Leto rojstva mora biti število med 1900 in 2099');
   return;
 }

    var data = {
      _id:userId,
      name: userName,
      email: userEmail,
      yofb: userAge,
      avatar:  userAvatar,
      vrstaIgralca: current,
      password: userPassword,
      deliLetoRojstva:isYofbEnabled,
      deliVrstoIgralca:isViEnabled,
      deliAvatar:isAvatarEnabled,
      deliIme:isImeEnabled

      
    };
    if(!userPassword)
      delete data.password;
console.log(data);

    fetch(apiServerUrl+'user/update', {
      method: 'POST',
      body: JSON.stringify({data}),
      headers: {
       Accept: 'application/json',
      'Content-Type': 'application/json'
 
      },
    })
      .then((response) => {
          console.log(response);
          if(response.ok)
            return response.json();
          else 
            throw new Error(response.statusText);
        })
      .then((responseJson) => {
        //Hide Loader
        setLoading(false);
        console.log(responseJson);
        // If server response message same as Data Matched
        if (responseJson) {
          setIsRegistraionSuccess(true);
          console.log(
            'Registration Successful. Please Login to proceed'
          );
            
          dispatch({ type: "loadUser",user:responseJson });
          AsyncStorage.setItem('@gd_user', JSON.stringify(responseJson));
          
        } else {
          setErrortext("Neznana napaka!");
        }
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
        setLoading(false);
        setErrortext("Napaka pri shranjevanju podatkov!");
       
      });





}


/*
 <View style={{alignItems: 'center'}}>
          <Image
            source={require('../assets/images/goran-dragic-logo.png')}
            style={{
              width: '90%',
              height: 100,
              resizeMode: 'contain',
              margin: 1,
            }}
          />
        </View>
*/

  return (
    
      <View style={{flex: 1}}>
    
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
          justifyContent: 'center',
          alignContent: 'center',
        }}>
         
         {(errortext||isRegistraionSuccess) != '' ? (
                <View style={styles.SectionStyle}>
            <Text style={styles.errorTextStyle}>
              {errortext}
            </Text>
               {isRegistraionSuccess&&<Text>Uspešno si spremenil svoje podatke.</Text>}
            </View>
          ) : null}
        <View style={styles.pozdravStyle}>
          <Text >
          Pozdravljen {userName}
          </Text>
        <Text >
        Uredi podatke o sebi:
        </Text>
        </View>
        <KeyboardAvoidingView enabled>
       
       
             <View style={styles.SectionStyle}>
      <View style={styles.inputlabelStyle}>
             <Text>Spremeni ime:</Text>
               <View style={styles.inputWrapperStyle}>
            <TextInput
              style={styles.inputStyle}
              onChangeText={(UserName) => {setUserName(UserName);setIsRegistraionSuccess(false);}}
                   value={userName}
              underlineColorAndroid="#f000"
              placeholder="Vnesi ime"
              placeholderTextColor="#8b9cb5"
              autoCapitalize="sentences"
              returnKeyType="next"
              onSubmitEditing={() =>
                emailInputRef.current && emailInputRef.current.focus()
              }
              blurOnSubmit={false}
            />
            </View>
            </View>
            <View style={styles.shareStyle}>
            <Text>Deli:</Text>
            <View style={styles.inputWrapperStyle}>
            <Switch
              style={{ marginLeft:5}}
         trackColor={{ false: '#ffffff', true: '#ffffff' }}
        thumbColor={isImeEnabled ? '#cb1a2b' : '#ffffff'}
        ios_backgroundColor="#1c2334"
        onValueChange={toggleImeSwitch}
        value={isImeEnabled}
      /></View>
      </View>
          </View>



                    <View style={styles.SectionStyle}>
      <View style={styles.inputlabelStyle}>
             <Text>Spremeni avatar:</Text>
               <View style={styles.inputWrapperStyle}>
               <View style={styles.carousel}>
                    <SwiperFlatList
                    renderAll={true}
        ref={carouselRef}
        //data={avatarData}
        //renderItem={renderAvatar}
        //style={styles.carousel}
        //index={userAvatarIndex}
        //itemWidth={40}
        //enableSnap={true}
        //itemHeight={10}
        //sliderWidth={80}
        //onScrollEnd={(item)=>console.log(item)}
        //sliderWidth ={40}
        //separatorWidth={0}
>
{
   avatarData.map((item, index) => (
     <View key={index} style={{width:40,justifyContent:'center',alignItems:'center'}}>
  <TouchableOpacity
                 
                 style={(item.index==userAvatarIndex)?styles.selectedAvatarItem:styles.AvatarItem}
                 onPress={() => {
                   console.log(carouselRef);
                   console.log(item);
                  //carouselRef.current.scrollToIndex({index:item.index});
                  setUserAvatar((item.index+1).toString());
                  setUserAvatarIndex(item.index);
                  console.log((item.index+1).toString());
                 }}>
        
         <Image style={(item.index==userAvatarIndex)?{width:30,height:30}:{width:25,height:25}} source={item.source}/>

         </TouchableOpacity>
         </View>
         ))
}

</SwiperFlatList>
</View>
  
            </View>
            </View>
            <View style={styles.shareStyle}>
            <Text>Deli:</Text>
            <View style={styles.inputWrapperStyle}>
            <Switch
              style={{ marginLeft:5}}
         trackColor={{ false: '#ffffff', true: '#ffffff' }}
        thumbColor={isAvatarEnabled ? '#cb1a2b' : '#ffffff'}
        ios_backgroundColor="#1c2334"
        onValueChange={toggleAvatarSwitch}
        value={isAvatarEnabled}
      /></View>
      </View>
          </View>
 
          
         
          <View style={styles.SectionStyle}>
          <View style={styles.inputlabelStyle}>
             <Text>Spremeni leto rojstva:</Text>
               <View style={styles.inputWrapperStyle}>
            <TextInput
              style={styles.inputStyle}
              onChangeText={(UserAge) =>{setUserAge(UserAge);setIsRegistraionSuccess(false);}}
              underlineColorAndroid="#f000"
              placeholder="Vnesi leto rojstva"
               value={userAge}
              placeholderTextColor="#8b9cb5"
              keyboardType="numeric"
              ref={ageInputRef}
              returnKeyType="next"
              onSubmitEditing={() =>
                addressInputRef.current &&
                addressInputRef.current.focus()
              }
              blurOnSubmit={false}
            />
            </View>
          </View>
          <View style={styles.shareStyle}>
            <Text>Deli:</Text>
             <View style={styles.inputWrapperStyle}>
            <Switch
            style={{ marginLeft:5}}
            trackColor={{ false: '#ffffff', true: '#ffffff' }}
            thumbColor={isYofbEnabled ? '#cb1a2b' : '#ffffff'}
            ios_backgroundColor="#1c2334"
            onValueChange={toggleYofbSwitch}
            value={isYofbEnabled}
          />
          </View>

        </View>
      </View>
          
          <View style={styles.SectionStyle}>
          <View style={{flex:1,flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
          <Text>Spremeni vrsto igralca:</Text>
           <View style={{flexDirection:'column', marginLeft:5,alignItems: 'center'}}>
            <Text>Deli:</Text>
            <Switch
             style={{ marginLeft:5}}
        trackColor={{ false: '#ffffff', true: '#ffffff' }}
        thumbColor={isViEnabled ? '#cb1a2b' : '#ffffff'}
        ios_backgroundColor="#1c2334"
        onValueChange={toggleViSwitch}
        value={isViEnabled}
      />
      </View>
      </View>
      </View>
       <View style={styles.SectionStyle}>
           <SwitchSelector style={{flex:1}}
  initial={getInitalIndex()}
 
  disableValueChangeOnPress={true}
  onPress={value => setCurrent(value)}
  textColor={'#ffffff'} //'#7a44cf'
  selectedColor={'#cb1a2b'}
  buttonColor={'#ffffff'}
  borderColor={'#ffffff'}
  backgroundColor={'#1c2334'}
  hasPadding
  options={[
    { label: "Rekreativec", value: "Rekreativec" }, 
    { label: "Amater", value: "Amater" },
    { label: "Profesionalec", value: "Profesionalec"}
  ]}

/>
     
           
           
            
          </View>
            <View style={styles.SectionStyle}>
            <View style={styles.inputlabelStyle}>
             <Text>Spremeni geslo:</Text>
              <View style={styles.inputWrapperStyle}>
            <TextInput
              style={styles.inputStyle}
              onChangeText={(UserPassword) =>
                {setUserPassword(UserPassword);
                setIsRegistraionSuccess(false);}
              }
              underlineColorAndroid="#f000"
              placeholder="Spremeni geslo"
              placeholderTextColor="#8b9cb5"
              ref={passwordInputRef}
              returnKeyType="next"
              secureTextEntry={true}
              onSubmitEditing={() =>
                ageInputRef.current &&
                ageInputRef.current.focus()
              }
              blurOnSubmit={false}
            />
            </View>
          </View>
          </View>

     

              <View style={styles.SectionStyle}>
          <TouchableOpacity
            style={styles.buttonStyle}
            activeOpacity={0.5}
            onPress={handleSubmitButton}>
            <Text style={styles.buttonTextStyle}>Shrani</Text>
          </TouchableOpacity>
       
           </View>
          <View style={styles.SectionStyle}>
           <TouchableOpacity
            style={styles.buttonStyle}
            activeOpacity={0.5}
            onPress={handleOdjavaButton}>
            <Text style={styles.buttonTextStyle}>Odjava</Text>
          </TouchableOpacity>
          </View>
        </KeyboardAvoidingView>
      </ScrollView>
    </View>
  
     
     
    
  );
}


const styles = StyleSheet.create({
  carousel: {
 flex:1,
  },
  AvatarItem: {
    width:40,
    height:40,
     flexDirection: 'column',
       alignItems: 'center',
       justifyContent:'center'
    },
     selectedAvatarItem: {
       backgroundColor:'white',
       borderRadius:40,

    width:40,
    height:40,
     flexDirection: 'column',
       alignItems: 'center',
       justifyContent:'center'
    },
  pozdravStyle: {
    flexDirection: 'column',
   
    marginTop: 10,
    marginLeft: 35,
    marginRight: 35,
    marginBottom: 5,
    
  },
  shareStyle:{
     flexDirection: 'column',
       alignItems: 'center',
       
 
  },
  switchStyle:{
    
 
  },

   inputWrapperStyle: {
     //flex:1,
        marginTop: 5,
        height:40,
        justifyContent:"center"
  },
     inputlabelStyle: {
        flex:1,
         flexDirection: 'column',
 
        
  },
    labelStyle: {
    flexDirection: 'column',
    
 
        
  },
  SectionStyle: {
    flexDirection: 'row',
    flexGrow:1,
   
    marginTop: 10,
    marginLeft: 35,
    marginRight: 35,

        
  },
  RadioButtonViewStyle: {
    flexDirection: 'column',
  
    marginTop: 20,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
  },
  buttonStyle: {
    flex:1,
    borderWidth: 0,
    color: '#FFFFFF',
    borderColor: '#7DE24E',
    backgroundColor: '#cb1a2b',
    height: 40,
    alignItems: 'center',
  
  },
  buttonTextStyle: {
    color: '#FFFFFF',
    paddingVertical: 10,
    fontSize: 16,
  },
  inputStyle: {
    flex: 1,
    width:'100%',
    color: 'white',
    paddingLeft: 15,
    paddingRight: 15,
   borderWidth: 1,
    borderColor: '#dadae8',
    height:40
    
    
  },
  errorTextStyle: {
    color: 'red',
    textAlign: 'center',
    fontSize: 14,
  },
  successTextStyle: {
    color: 'white',
    textAlign: 'center',
    fontSize: 18,
    padding: 30,
  },
});
