import React, { useContext, useEffect } from 'react';
import { StyleSheet } from 'react-native';

import Igraj from '../components/Igraj';
import { Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import { UserContext } from "../state/User"

export default function TabTwoScreen({navigation,route}) {
   const colorScheme = useColorScheme();
   const user=null;
    const [ state, dispatch ] = React.useContext(UserContext);
    console.log("state");
   console.log(state);
   
  return (
    <View style={styles.container}>
      {state&&<Text style={styles.text}>
        Pozdravljen {state.name}
      </Text>
     }
      <Text style={styles.text}>
        Možnost ustvarjanja ekipe bo na voljo ob naslednji nadgradnji!
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding:10
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  text:{
    textAlign:"center"
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
