import * as React from 'react';
import { StyleSheet,ScrollView,Dimensions,ImageBackground, Platform } from 'react-native';

//import Igraj from '../components/Igraj';
import { Text, View } from '../components/Themed';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import { ListItem, Avatar,Badge } from 'react-native-elements'
import {useState, createRef} from 'react';

import avatar1 from '../assets/images/avatars/1.png';
import avatar2 from '../assets/images/avatars/2.png';
import avatar3 from '../assets/images/avatars/3.png';
import avatar4 from '../assets/images/avatars/4.png';
import avatar5 from '../assets/images/avatars/5.png';
import avatar6 from '../assets/images/avatars/6.png';
import avatar7 from '../assets/images/avatars/7.png';
import avatar8 from '../assets/images/avatars/8.png';
import avatar9 from '../assets/images/avatars/9.png';
import anonimen from '../assets/images/avatars/anonimen.png';
import igrisce from '../assets/images/igrisce.jpg';

import SwitchSelector from "react-native-switch-selector";
import DateTimePicker from '@react-native-community/datetimepicker';
import moment from 'moment';

import Constants from 'expo-constants';
const apiServerUrl=Constants.manifest.extra.apiServerUrl;

export default function IgralciScreen({ route,navigation }) {
   const colorScheme = useColorScheme();
   
   const [igralci, setIgralci] = useState(route.params.list);
   const [vidniIgralci, setVidniIgralci] = useState(route.params.list);
   const [igrisceData, setIgrisca] = useState(route.params.igrisce);
   const screenWidth = Dimensions.get('window').width;
 
  const [dan, setDan] = useState('zdaj');
    const [date, setDate] = useState(new Date());
  const [mode, setMode] = useState('date');
  const [show, setShow] = useState(false);
   
   
const [avatarWidth, setAvatarWidth] = useState((screenWidth*0.4648*0.2));
const [avatarHeight, setAvatarHeight] = useState((screenWidth*0.4648*0.2));

const [badgeWidth, setBadgeWidth] = useState((screenWidth*0.4648*0.04));


const [imamEkipoC, setImamEkipoC] = useState(igralci.filter(i=>i.vloga=="Igram").length);
const [iscemEkipoC, setIscemEkipoC] = useState(igralci.filter(i=>i.vloga=="Iščem").length);
const [treniramC, setTreniramC] = useState(igralci.filter(i=>i.vloga=="Treniram").length);
const [gledamC, setGledamC] = useState(igralci.filter(i=>i.vloga=="Gledam").length);

const [namenIgralcev, setNamenIgralcev] = useState("Prisotni:");

//dodaj še event na klik na izpis ure, da se odpre edit za uro, razlika med ios in and
const onChange = (event, selectedDate) => {
    const currentDate = selectedDate || date;
    setShow(Platform.OS === 'ios');
    setDate(currentDate);

  posodobiIgralceFilter(dan,currentDate);
  };


const showMode = (currentMode) => {
    setShow(true);
    setMode(currentMode);
  };

  const showDatepicker = () => {
    showMode('date');
  };

  const showTimepicker = () => {
    showMode('time');
  };


const posodobiIgralceFilter=(dan,date)=>{
   fetch(`${apiServerUrl}igralec/${igrisceData.id}/${dan}/${moment(date).format('HH')}/${moment(date).format('mm')}`, {
      method: 'GET',
      headers: {
       Accept: 'application/json',
      'Content-Type': 'application/json'
 
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        //Hide Loader
        console.log(responseJson);
        // če prijava uspe, pelji tam kjer si prišel iz prijave ozirma na registracijske podatke zaenkrat
        if (responseJson.napaka) {
        } 
        else {
        let igralci=responseJson;
        setIgralci(igralci);
        setVidniIgralci(igralci);
        setImamEkipoC(igralci.filter(i=>i.vloga=="Igram").length);
        setIscemEkipoC(igralci.filter(i=>i.vloga=="Iščem").length);
        setTreniramC(igralci.filter(i=>i.vloga=="Treniram").length);
        setGledamC(igralci.filter(i=>i.vloga=="Gledam").length);
        
         
       
          
        }
      })
      .catch((error) => {
        //dodaj na treniraj seznam še error, če se ne naložijo videi!!!
        console.error(error);
      });



}


const posodobiVrsteIgralcev=(vrstaIgralca)=>{
        setVidniIgralci(igralci.filter(i=>i.vloga==vrstaIgralca));
}
  
 const updateIgrisce= () => {
   fetch(`${apiServerUrl}igralec/${igrisce.id}`,{
      method: 'GET',
      headers: {
       Accept: 'application/json',
      'Content-Type': 'application/json'
 
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        //Hide Loader
        console.log(responseJson);
        // če prijava uspe, pelji tam kjer si prišel iz prijave ozirma na registracijske podatke zaenkrat
        if (responseJson.napaka) {
        } 
        else {
        console.log(    responseJson          );
        navigation.navigate('Igralci',{list:responseJson,title:igrisceData.naziv});
         
       
          
        }
      })
      .catch((error) => {
        //dodaj na treniraj seznam še error, če se ne naložijo videi!!!
        console.error(error);
      });
   }

   const vrniAvatarImage= (igralec) => {
    
     //if(!igralec.deliAvatar)
       //return igralec.anonimen;

    switch (igralec.avatar) {
      case "1":
        return avatar1;
      case "2":
        return avatar2;
      case "3":
        return avatar3;
      case "4":
        return avatar4;
      case "5":
        return avatar5;
      case "6":
        return avatar6;
      case "7":
        return avatar7;
      case "8":
        return avatar8;
      case "9":
        return avatar9;
      default:
        return anonimen;
    }
    
   }
    

//   <Avatar source={l.avatar?require(`../assets/images/treningImages/${l.avatar}.jpg`):require('../assets/images/treningImages/placeholder.jpg')} />
   //  <ListItem.Chevron  color="white" />

   /*
   Time gre stran
  <View>          
    {show && (
        <DateTimePicker
          value={date}
          mode={mode}
           textColor="white"
          is24Hour={true}
          display="spinner"
          onChange={onChange}
        />
      )}
    {(Platform.OS !== 'ios')&&(dan!='zdaj')&&
     <Text style={styles.androidTime}>{moment(date).format('HH:mm')}</Text>
    }

          </View>

   */
  return (
    <View style={styles.container}>
    <View style={styles.TextSectionStyle}>
        <Text>Aktivni na igrišču:</Text>
         </View>     
    <View style={styles.SectionStyle}>
          <SwitchSelector style={{flex:1}}
  initial={0}
  onPress={value =>{
    setDan(value);
    /*if(value=="zdaj")
      setShow(false);
    else{
      setMode('time');
      setShow(true);
    }
*/
     posodobiIgralceFilter(value,date);
    
  }}
  textColor={'#ffffff'} //'#7a44cf'
  selectedColor={'#cb1a2b'}
  buttonColor={'#ffffff'}
  borderColor={'#ffffff'}
  backgroundColor={'#1c2334'}
  hasPadding
  options={[
    { label: "Zdaj", value: "zdaj" }, 
    { label: "Danes", value: "danes" },
    { label: "Jutri", value: "jutri"} 
  ]}

/>
  </View>

          <View style={styles.IgrisceStyle}>
          <ImageBackground source={igrisce} style={{width:screenWidth, height:screenWidth*0.4648}}>
{imamEkipoC>0&&<View style={[{top: ((screenWidth*0.4648)/2-avatarHeight/2),left:((screenWidth/3)-avatarWidth/2)},styles.playerWrap]}
  onLayout={(event) => {
  
  //setAvatarWidth(event.nativeEvent.layout.width);
  //setAvatarHeight(event.nativeEvent.layout.height);
  
}}
>
  <Avatar 
  onPress={()=>{posodobiVrsteIgralcev("Igram");}}
  overlayContainerStyle={{backgroundColor: 'white'}}
    rounded
    source={avatar2}
    size={(screenWidth*0.4648*0.2)}
  />
  <Badge

    value={imamEkipoC}
    containerStyle={{ position: 'absolute', bottom: -5, right: -5}}
    badgeStyle={{ backgroundColor:"#1c2334",borderWidth:1.5}}
  />
</View>
}

{iscemEkipoC>0&&<View style={[{top: ((screenWidth*0.4648)*3/4-avatarHeight/2),left:((screenWidth*0.40)-avatarWidth/2)},styles.playerWrap]}
 onLayout={(event) => {
  
  //setAvatarWidth(event.nativeEvent.layout.width);
  //setAvatarHeight(event.nativeEvent.layout.height);
  
}}
>
  <Avatar 
   onPress={()=>{posodobiVrsteIgralcev("Iščem");}}
  overlayContainerStyle={{backgroundColor: 'white'}}
    rounded
    source={avatar1}
     size={(screenWidth*0.4648*0.2)}
  />
  <Badge
    value={iscemEkipoC}
    containerStyle={{ position: 'absolute', bottom: -5, right: -5 }}
    badgeStyle={{ backgroundColor:"#1c2334",borderWidth:1.5}}
  />
</View>
}

{treniramC>0&&<View style={[{top: ((screenWidth*0.4648)/2-avatarHeight/2),left:((0.8*screenWidth)-avatarWidth/2)},styles.playerWrap]}
 onLayout={(event) => {
  
 // setAvatarWidth(event.nativeEvent.layout.width);
  //setAvatarHeight(event.nativeEvent.layout.height);
  
}}
>
  <Avatar 
    onPress={()=>{posodobiVrsteIgralcev("Treniram");}}
    overlayContainerStyle={{backgroundColor: 'white'}}
    rounded
    source={avatar5}
   size={(screenWidth*0.4648*0.2)}
  />
  <Badge
    value={treniramC}
    containerStyle={{ position: 'absolute', bottom: -5, right: -5 }}
    badgeStyle={{ backgroundColor:"#1c2334",borderWidth:1.5}}
  />
</View>}

{gledamC>0&&<View style={[{top: ((screenWidth*0.4648)/4-avatarHeight/2),left:((0.45*screenWidth)-avatarWidth/2)},styles.playerWrap]}
 onLayout={(event) => {
  
  //setAvatarWidth(event.nativeEvent.layout.width);
  //setAvatarHeight(event.nativeEvent.layout.height);
  
}}
>
  <Avatar 
    onPress={()=>{posodobiVrsteIgralcev("Gledam");}}
  overlayContainerStyle={{backgroundColor: 'white'}}
    rounded
    source={avatar8}
    size={(screenWidth*0.4648*0.2)}
  />
  <Badge
    value={gledamC}
    containerStyle={{ position: 'absolute', bottom: -5, right: -5 }}
    badgeStyle={{ backgroundColor:"#1c2334",borderWidth:1.5}}
  />
</View>
}



         </ImageBackground>
          </View >
          {namenIgralcev&&<View style={styles.namenTitle}>
            <Text>{namenIgralcev}</Text>
          </View>}
    <ScrollView style={styles.scroll}>
    
     {
    vidniIgralci.map((l, i) => (
      <ListItem containerStyle={{backgroundColor:Colors[colorScheme].tabBackground, borderBottomColor: 'white'}}
             key={i} bottomDivider
      //onPress={() => {posodobiIgralceFilter(l);}}
      >
     
        <Avatar   source={vrniAvatarImage(l)} rounded />
        <ListItem.Content >
          <ListItem.Title style={{ color: 'white', fontFamily: 'Exo_400Regular' }}>{l.ime?l.ime:"Anonimnež"}</ListItem.Title>
          <View style={styles.subTitle}>
            <Text style={{ color:  Colors[colorScheme].tabIconSelected}}>{l.vloga}</Text>
            {l.letoRojstva&&<Text style={{ color:  Colors[colorScheme].tabIconSelected}}>, {new Date().getFullYear()-l.letoRojstva} let</Text>}
            {l.vrstaIgralca&&<Text style={{ color:  Colors[colorScheme].tabIconSelected}}>, {l.vrstaIgralca}</Text>}
          </View>
          
        </ListItem.Content>
         <View>
           <Text>{moment(l.casPrihoda).format('HH:mm')}</Text>
          </View>
      </ListItem>
    ))
  }
  </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  playerWrap:{
    position:'absolute',
backgroundColor:'transparent',

  },

  androidTime:{
    marginTop: 5,
    marginLeft: 35,
    marginRight: 35,
    marginBottom:10,
     textAlign: 'center',
     fontSize:24
  },
  container: {
     flex: 1,
    justifyContent: 'flex-start',
    
    alignContent: 'center',
  },
  namenTitle: {
    flexDirection: 'row',
  
    marginTop: 10,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
  },
  
   SectionStyle: {
    flexDirection: 'row',
  
    marginTop: 5,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
  },
  scroll:{
width:Dimensions.get('window').width
  },
  subTitle:{
    flexDirection:"row"
  },
  IgrisceStyle:{

  },
  TextSectionStyle: {
    flexDirection: 'column',
    marginTop: 15,
    marginLeft: 35,
    marginRight: 35,
    margin: 5,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  backgroundVideo: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
});
