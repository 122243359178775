/**
 * Learn more about createBottomTabNavigator:
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */

import { Ionicons,FontAwesome5   } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import { Text, View } from '../components/Themed';
import * as React from 'react';
import {Image} from 'react-native';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import DomovScreen from '../screens/DomovScreen';
import TabTwoScreen from '../screens/TabTwoScreen';
import PrijavaScreen from '../screens/PrijavaScreen';
import ProfilScreen from '../screens/ProfilScreen';
import RegisterScreen from '../screens/RegisterScreen';
import EkipaScreen from '../screens/EkipaScreen';
import TreningiScreen from '../screens/TreningiScreen';
import VideoScreen from '../screens/VideoScreen';
import IgriscaScreen from '../screens/IgriscaScreen';
import IgriscaNajavaScreen from '../screens/IgriscaNajavaScreen';
import NajavaScreen from '../screens/NajavaScreen';
import IgralciScreen from '../screens/IgralciScreen';
import OProjektuScreen from '../screens/OProjektuScreen';
import TreningiVrstaScreen from '../screens/TreningiVrstaScreen';

import OpisIgriscScreen from '../screens/OpisIgriscScreen';
import PodamdaigramScreen from '../screens/PodamdaigramScreen';
import OpisAplikacijeScreen from '../screens/OpisAplikacijeScreen';
import SponzorjiScreen from '../screens/SponzorjiScreen';

import { BottomTabParamList, TabOneParamList, TabTwoParamList,ProfileParamList } from '../types';
import { UserContext } from "../state/User";
const BottomTab = createBottomTabNavigator<BottomTabParamList>();

export default function BottomTabNavigator() {
  const colorScheme = useColorScheme();
/*
<BottomTab.Screen
        name="Igraj"
        component={TabTwoNavigator}
        options={{
          tabBarIcon: ({ color }) => <FontAwesome5 name="basketball-ball" size={24} color={color} />,
        }}
      />
*/

  return (
    <BottomTab.Navigator
      initialRouteName="Domov"
      tabBarOptions={{ iconStyle:{marginTop:3},  labelStyle: {fontFamily: "Exo_400Regular",textTransform:'uppercase', marginBottom:3}, inactiveTintColor:Colors[colorScheme].text, activeTintColor: Colors[colorScheme].tabIconSelected, activeBackgroundColor: Colors[colorScheme].tabBackground,inactiveBackgroundColor  : Colors[colorScheme].tabBackground }}>
      <BottomTab.Screen
        name="Domov"
        component={TabOneNavigator}
        options={{
     //     unmountOnBlur:true,
          tabBarIcon: ({ color }) => <FontAwesome5 name="home" size={22} color={color} />,
        }}
      />
      
       <BottomTab.Screen
        name="Najava"
        component={NajavaNavigator}
        options={{
          //unmountOnBlur:true,
          title:'Najavi se',
          tabBarIcon: ({ color }) => <FontAwesome5 name="map-marker-alt" size={22} color={color} />,
        }}
      />
       <BottomTab.Screen
        name="Profil"

        component={ProfilNavigator}
        options={{
           unmountOnBlur:true,
          tabBarIcon: ({ color }) =><FontAwesome5 name="user-alt" size={22} color={color} />,
        }}
      />
      <BottomTab.Screen
        name="Ekipa"
        component={EkipaNavigator}
        options={{
          tabBarIcon: ({ color }) => <FontAwesome5 name="users" size={22} color={color} />,
        }}
      />
    </BottomTab.Navigator>
  );
}

// You can explore the built-in icon families and icons on the web at:
// https://icons.expo.fyi/
function TabBarIcon(props: { name: React.ComponentProps<typeof Ionicons>['name']; color: string }) {
  return <Ionicons size={30} style={{ marginBottom: -3 }} {...props} />;
}

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab
/*
  <FontAwesome5 name="qrcode" style={{marginLeft: 'auto'}} size={24} color={Colors[colorScheme].tabIconDefault} />
           <FontAwesome5 name="envelope" size={24} style={{marginLeft: 15}} color={Colors[colorScheme].tabIconDefault} />
*/
const TabOneStack = createStackNavigator<TabOneParamList>();

function TabOneNavigator() {
   const colorScheme = useColorScheme();
   const headerStyle= {backgroundColor: Colors[colorScheme].tabBackground};
   const headerTitleStyle={color:'white', fontFamily:"Exo_400Regular"};
  return (
    <TabOneStack.Navigator>
      <TabOneStack.Screen
        name="Domov"
        component={DomovScreen}
        options={{ headerTitle: <View style={{width:'100%', flex:1,flexDirection:'row', justifyContent:'flex-start'}}>
        <Text>Pametno igrišče #PODAMDAIGRAM</Text>
         
        </View>

        ,headerStyle: headerStyle,headerTitleStyle:headerTitleStyle}}
      />

      <TabOneStack.Screen
        name="Video"
        component={VideoScreen}
        options={({ route }) => ({ title: route.params.video.naslov,headerStyle:headerStyle,headerTintColor:Colors[colorScheme].tabIconDefault,headerTitleStyle:headerTitleStyle })}
      />

       <TabOneStack.Screen
        name="Igrisca"
        component={IgriscaScreen}
        options={{ headerTitle: <View style={{width:'100%', flex:1,flexDirection:'row', justifyContent:'flex-start'}}>
        <Text>Pametna igrišča</Text>
           
        </View>

        ,headerStyle: headerStyle,headerTintColor:Colors[colorScheme].tabIconDefault,headerTitleStyle:headerTitleStyle }}
      />
      <TabOneStack.Screen
        name="Igralci"
        component={IgralciScreen}
        options={({ route }) =>({ title: route.params.title, headerStyle: headerStyle,headerTintColor:Colors[colorScheme].tabIconDefault,headerTitleStyle:headerTitleStyle })}
      />

      <TabOneStack.Screen
        name="KdoIgra"
        component={DomovScreen}
        options={{ headerTitle: <View style={{width:'100%', flex:1,flexDirection:'row', justifyContent:'flex-start'}}>
        <Text>Kdo Igra</Text>
           
        </View>

        ,headerStyle: headerStyle,headerTintColor:Colors[colorScheme].tabIconDefault,headerTitleStyle:headerTitleStyle }}
      />
      <TabOneStack.Screen
        name="Videi"
        component={TreningiScreen}
       options={({ route }) => ({ title: route.params.title,headerStyle: headerStyle,headerTintColor:Colors[colorScheme].tabIconDefault,headerTitleStyle:headerTitleStyle })}
      />
       <TabOneStack.Screen
        name="Treningi"
        component={TreningiVrstaScreen}
       options={({ route }) => ({ title: route.params.title,headerStyle: headerStyle,headerTintColor:Colors[colorScheme].tabIconDefault,headerTitleStyle:headerTitleStyle })}
      />
      
      <TabOneStack.Screen
        name="OProjektu"
        component={OProjektuScreen}
        options={{ headerTitle: <View style={{width:'100%', flex:1,flexDirection:'row', justifyContent:'flex-start'}}>
        <Text>O projektu</Text>
        
        </View>

        ,headerStyle: headerStyle,headerTintColor:Colors[colorScheme].tabIconDefault,headerTitleStyle:headerTitleStyle }}
      />
       <TabOneStack.Screen
        name="OpisIgrisc"
        component={OpisIgriscScreen}
        options={{ headerTitle: <View style={{width:'100%', flex:1,flexDirection:'row', justifyContent:'flex-start'}}>
        <Text>Igrišče Goran Dragič</Text>
        
        </View>

        ,headerStyle: headerStyle,headerTintColor:Colors[colorScheme].tabIconDefault,headerTitleStyle:headerTitleStyle }}
      />

         <TabOneStack.Screen
        name="Podamdaigram"
        component={PodamdaigramScreen}
        options={{ headerTitle: <View style={{width:'100%', flex:1,flexDirection:'row', justifyContent:'flex-start'}}>
        <Text>#PODAMDAIGRAM</Text>
        
        </View>

        ,headerStyle: headerStyle,headerTintColor:Colors[colorScheme].tabIconDefault,headerTitleStyle:headerTitleStyle }}
      />

         <TabOneStack.Screen
        name="OpisAplikacije"
        component={OpisAplikacijeScreen}
        options={{ headerTitle: <View style={{width:'100%', flex:1,flexDirection:'row', justifyContent:'flex-start'}}>
        <Text>Aplikacija</Text>
        
        </View>

        ,headerStyle: headerStyle,headerTintColor:Colors[colorScheme].tabIconDefault,headerTitleStyle:headerTitleStyle }}
      />

      <TabOneStack.Screen
        name="Sponzorji"
        component={SponzorjiScreen}
        options={{ headerTitle: <View style={{width:'100%', flex:1,flexDirection:'row', justifyContent:'flex-start'}}>
        <Text>Sponzorji</Text>
        
        </View>

        ,headerStyle: headerStyle,headerTintColor:Colors[colorScheme].tabIconDefault,headerTitleStyle:headerTitleStyle }}
      />

    </TabOneStack.Navigator>
  );
}

const TabTwoStack = createStackNavigator<TabTwoParamList>();

function NajavaNavigator() {
   const colorScheme = useColorScheme();
    const headerStyle= {backgroundColor: Colors[colorScheme].tabBackground};
   const headerTitleStyle={color:'white', fontFamily:"Exo_400Regular"};
  return (
    <TabTwoStack.Navigator>
      <TabTwoStack.Screen
        name="NajavaIgrisca"
        component={IgriscaNajavaScreen}
          options={{ headerTitle: <View style={{width:'100%', flex:1,flexDirection:'row', justifyContent:'flex-start'}}>
        <Text>Seznam igrišč</Text>
       
        </View>

         ,headerStyle: headerStyle,headerTintColor:Colors[colorScheme].tabIconDefault,headerTitleStyle:headerTitleStyle }}
    />
     <TabTwoStack.Screen
        name="Najava"
        component={NajavaScreen}
          options={{ headerTitle: <View style={{width:'100%', flex:1,flexDirection:'row', justifyContent:'flex-start'}}>
        <Text>Najava</Text>
       
        </View>

         ,headerStyle: headerStyle,headerTintColor:Colors[colorScheme].tabIconDefault,headerTitleStyle:headerTitleStyle }}
    />
    </TabTwoStack.Navigator>
  );
}

const EkipaStack = createStackNavigator<TabTwoParamList>();

function EkipaNavigator() {
   const colorScheme = useColorScheme();
  return (
    <EkipaStack.Navigator>
      <EkipaStack.Screen
        name="Ekipa"
        component={EkipaScreen}
          options={{ headerTitle: <View style={{width:'100%', flex:1,flexDirection:'row', justifyContent:'flex-start'}}>
        <Text>Ekipa</Text>
        </View>

         ,headerStyle: {backgroundColor: Colors[colorScheme].tabBackground},headerTintColor:Colors[colorScheme].tabIconDefault}}
    />
    </EkipaStack.Navigator>
  );
}

const ProfilStack = createStackNavigator<ProfileParamList>();


function ProfilNavigator() {
   const colorScheme = useColorScheme();
     const [ state, dispatch ] = React.useContext(UserContext);

  return (
    <ProfilStack.Navigator initialRouteName={state.user.niPrijavljen?"Prijava":"Profil"}>
      <ProfilStack.Screen
        name="Prijava"
        component={PrijavaScreen}
          options={{ headerTitle: <View style={{width:'100%', flex:1,flexDirection:'row', justifyContent:'flex-start'}}>
        <Text>Prijava</Text>
           </View>

         ,headerStyle: {backgroundColor: Colors[colorScheme].tabBackground},headerTintColor:Colors[colorScheme].tabIconDefault}}
    />
     <ProfilStack.Screen
        name="Profil"
        component={ProfilScreen}
          options={{ headerTitle: <View style={{width:'100%', flex:1,flexDirection:'row', justifyContent:'flex-start'}}>
        <Text>Profil</Text>
           </View>

         ,headerStyle: {backgroundColor: Colors[colorScheme].tabBackground},headerTintColor:Colors[colorScheme].tabIconDefault}}
    />
    <ProfilStack.Screen
        name="Registracija"
        component={RegisterScreen}
          options={{ headerTitle: <View style={{width:'100%', flex:1,flexDirection:'row', justifyContent:'flex-start'}}>
        <Text>Registracija</Text>
           </View>

         ,headerStyle: {backgroundColor: Colors[colorScheme].tabBackground},headerTintColor:Colors[colorScheme].tabIconDefault}}
    />
    </ProfilStack.Navigator>
  );
}
