import * as React from 'react';
import { StyleSheet,ScrollView,TouchableOpacity,Dimensions,Image } from 'react-native';

import Igraj from '../components/Igraj';
import { Text, View} from '../components/Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import aplikacija from '../assets/images/aplikacija.png';
import zmaj from '../assets/images/zmaj.png';
import kdoigra from '../assets/images/kdoigra.png';
import sponzorji from '../assets/images/sponzorji.png';
const scale = size => windowWidth / guidelineBaseWidth * size;
const verticalScale = size => windowHeight / guidelineBaseHeight * size;
const moderateScale = (size, factor = 0.5) => size + ( scale(size) - size ) * factor;
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const guidelineBaseWidth = 350;
const guidelineBaseHeight = 680;
export default function OProjektuScreen({navigation}) {
   const colorScheme = useColorScheme();
  return (
    <View style={styles.container}>
       <View style={styles.afterLogoScreen}>      
        <View style={styles.domovActionButtonWrapper}>      
         <TouchableOpacity
            style={styles.domovActionButton}
            activeOpacity={0.5}
            onPress={()=>{navigation.navigate("OpisIgrisc")}}>
         
            <Image source={kdoigra} resizeMode={"contain"} style={styles.buttonImage}/>
           
            <Text style={styles.buttonText}>Igrišče</Text>
          
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.domovActionButton}
            activeOpacity={0.5}
           onPress={()=>{navigation.navigate("Podamdaigram")}}>
              <Image source={zmaj} resizeMode={"contain"} style={styles.buttonImage}/>
            <Text style={styles.buttonText}>#PODAMDAIGRAM</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.domovActionButton}
            activeOpacity={0.5}
            onPress={()=>{navigation.navigate("OpisAplikacije")}}>
              <Image source={aplikacija} resizeMode={"contain"} style={styles.buttonImage}/>
            <Text style={styles.buttonText}>Aplikacija</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.domovActionButton}
            activeOpacity={0.5}
           onPress={()=>{navigation.navigate("Sponzorji")}}>
              <Image source={sponzorji} resizeMode={"contain"} style={styles.buttonImage}/>
            <Text style={styles.buttonText}>Sponzorji</Text>
          </TouchableOpacity>
     </View>
    </View>
  </View>

  );
}

const styles = StyleSheet.create({
    imageContainer:{
    marginVertical: 15,
    marginLeft:35,
    marginRight:35
    

  },
 
   buttonImage:{
    width:Dimensions.get('window').width*0.3,
    height:Dimensions.get('window').width*0.3,
    marginBottom:verticalScale(10)
  },
  buttonText:{
    color:'#ffffff',
    fontFamily:'Exo_700Bold',
    textTransform:'uppercase',
    fontSize:verticalScale(13)
  },
  domovActionButton:{
   width:(Dimensions.get('window').width/2-verticalScale(30)),
   height:(Dimensions.get('window').width/2-verticalScale(30)),
    borderColor:'#ffffff',
    //backgroundColor:'#ffffff',
    borderWidth: 1,
    margin: verticalScale(10),
    alignItems:'center',
    justifyContent:'space-evenly'

  },
  afterLogoScreen:{
    flex:1,
    alignItems:'flex-start',
    justifyContent:'center'

  },
  domovActionButtonWrapper:{
  
  display:'flex',
  flexWrap:'wrap',
  flexDirection:'row',
   alignItems:'center',
    justifyContent:'center'
  

  }
  ,
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
  
    height: 1,
    width: '80%',

  },
   getStartedContainer: {
   
    flexDirection:'column',
    justifyContent:"space-between",
    alignItems: 'center',
    marginHorizontal: 50,
  },
   
  homeScreenFilename: {
    marginVertical: 7,
  },
  codeHighlightContainer: {
    borderRadius: 3,
    paddingHorizontal: 4,
  },
  getStartedText: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  helpContainer: {
    marginTop: 15,
    marginHorizontal: 20,
    alignItems: 'center',
  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    textAlign: 'center',
  },
  video: {
    alignSelf: 'center',
   
    width: Dimensions.get('window').width,
    
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
     flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  text:{
    textAlign:"center"
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
