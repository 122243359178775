
import { StyleSheet, TouchableOpacity,Dimensions, Platform  } from 'react-native';
import Colors from '../constants/Colors';
import { MonoText } from '../components/StyledText';
import { Text, View } from '../components/Themed';
import React, {useState, createRef} from 'react';
import RadioButton from 'expo-radio-button'

import SwitchSelector from "react-native-switch-selector";
import DateTimePicker from '@react-native-community/datetimepicker';
import moment from 'moment';
import { UserContext } from "../state/User"

import Constants from 'expo-constants';
const apiServerUrl=Constants.manifest.extra.apiServerUrl;
//import Loader from '../components/Loader';
import { Ionicons,FontAwesome5   } from '@expo/vector-icons';

import NumericInput from 'react-native-numeric-input'



import {
  ActivityIndicator,


  TextInput,
  Image,
  KeyboardAvoidingView,
  Keyboard,
  ScrollView,
  Picker
} from 'react-native';


/*
poglej če je user_id v ls, če je, poglej, če so ostali podatki v ls 
naloži podatke in jih prepiši (če imaš net..., če ne vzami iz ls, če n v ls, izpiši napako)
Logout gumb gre v urejanje profila!

ni user_id, pokaži prijavo, spodaj pa link, da če še nisi registriran, si ustvari račun
register, po register, dobiš nazaj user_id, shrani ga, in pokaži možnost urejanja profila
(spremeni geslo na mail??, kasneje)

po prijavi dodaj user_id, dobi podatke in prikaži urejanje profila

*/


export default function NajavaScreen({ route, navigation}) {
  

  const igrisce=route.params.igrisce;
  const [ state, dispatch ] = React.useContext(UserContext);
  // const [user, setUser] = useState(state.user);
   const [loading, setLoading] = useState(false);
   const [vloga, setVloga] = useState('Imam ekipo');
   const [dan, setDan] = useState('zdaj');

    const [date, setDate] = useState(new Date());
  
  const [mode, setMode] = useState('date');
  const [show, setShow] = useState(false);
 const [
    uspesnaNajava,
    setUspesnaNajava
  ] = useState(false);




const onChange = (event, selectedDate) => {
    const currentDate = selectedDate || date;
    setShow(Platform.OS === 'ios');
    setDate(currentDate);
  };


const showMode = (currentMode) => {
    setShow(true);
    setMode(currentMode);
  };

  const showDatepicker = () => {
    showMode('date');
  };

  const showTimepicker = () => {
    showMode('time');
  };

function handleHelpPress() {
  



}



const handleSubmitButton = () =>{

var igralec = {
   "vloga":vloga   ,
   "igrisce":igrisce.id,
};
 igralec.casPrihoda=date;
if(dan=="jutri"){
  var result = new Date(date);
 igralec.casPrihoda=result.setDate(result.getDate()+1);
}
console.log(state.user);

if(state.user.niPrijavljen){
 igralec.ime=state.user.name;
 igralec.avatar="1";
}
//else{
  igralec.id=state.user._id;

if(state.user.deliLetoRojstva)
    igralec.letoRojstva=state.user.yofb;
if(state.user.deliIme)
    igralec.ime=state.user.name;
if(state.user.deliVrstoIgralca)
    igralec.vrstaIgralca=state.user.vrstaIgralca;
  if(state.user.deliAvatar)
    igralec.avatar=state.user.avatar;
  // }

    fetch(apiServerUrl+'igralec', {
      method: 'POST',
      body: JSON.stringify(igralec),
      headers: {
       Accept: 'application/json',
      'Content-Type': 'application/json'
 
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        //Hide Loader
        setLoading(false);
        console.log(responseJson);
        // If server response message same as Data Matched
        //if (responseJson.status === 'success') {
          //setIsRegistraionSuccess(true);
          console.log('Najava uspešna');
          setUspesnaNajava(true);
      })
      .catch((error) => {
        //Hide Loader
        setLoading(false);
        console.error(error);
      });






}
//izpis igrišča
//izpis tvojih podatkov, če so, oziroma anonimnež, če ni podatkov
//izbira zdaj, danes, jutri
//pri izbiri danes jutri, še izbira ure
//vloga


  return (
    
      <View style={styles.mainBody}>
     
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
            flex: 1,
          
          alignContent: 'center',
          justifyContent:"flex-start"
        }}>
        <View style={styles.TextSectionStyle}>
        <Text >
        Pozdravljen {state.user.name}
        </Text>
        {state.user.niPrijavljen&&<Text>Če želiš o sebi z drugimi deliti več podatkov, se moreš <Text style={styles.linkStyle} onPress={()=>navigation.navigate("Profil",{screen:"Prijava"})}>prijaviti!</Text></Text>}
        </View>
        <View style={styles.TextSectionStyle}>
        <Text><Text>Na igrišče </Text><Text style={{color:"#cb1a2b", fontFamily:"Exo_700Bold"}}>{igrisce.naziv}</Text><Text> pridem: </Text></Text>
         </View>     
          <View style={styles.SectionStyle}>
          <SwitchSelector style={{flex:1}}
  initial={0}
  onPress={value =>{
    setDan(value)
    if(value=="zdaj")
      setShow(false);
    else{
      setMode('time');
      setShow(true);
    }
  }}
  textColor={'#ffffff'} //'#7a44cf'
  selectedColor={'#cb1a2b'}
  buttonColor={'#ffffff'}
  borderColor={'#ffffff'}
  backgroundColor={'#1c2334'}
  hasPadding
  options={[
    { label: "Zdaj", value: "zdaj" }, 
    { label: "Danes", value: "danes" },
    { label: "Jutri", value: "jutri"} 
  ]}

/>
  </View>
  <View>          
    {show && (
        <DateTimePicker
          value={date}
          mode={mode}
           textColor="white"
          is24Hour={true}
          display="spinner"
          onChange={onChange}
        />
      )}
  

    {(Platform.OS == 'android')&&(dan!='zdaj')&&
    <Text style={styles.androidTime}>{moment(date).format('HH:mm')}</Text>
    // onpress bi moral tudi dati show na true
     }
         {(Platform.OS == 'web')&&(dan!='zdaj')&&
         <View style={{flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
              <View style={{flexDirection:'column',alignItems:'center'}}>
              <TouchableOpacity
              onPress={()=>{
                if(parseInt(moment(date).format('HH'))<23)
                setDate(moment(date).add(1,'hours'));
                else
                   setDate(moment(date).set({hours:0}));
              }}>
              <FontAwesome5 name="chevron-up" color="white"/>
              </TouchableOpacity>
              <Text style={styles.webTime}> 
              {moment(date).format('HH')}
               </Text>
               <TouchableOpacity
              onPress={()=>{
                if(parseInt(moment(date).format('HH'))>0)
                setDate(moment(date).add(-1,'hours'));
                else
                setDate(moment(date).set({hours:23}));
              }}>
              <FontAwesome5 name="chevron-down"  color="white"/>
              </TouchableOpacity>
              
               </View>

           <Text style={styles.webTime}> 
          :
           </Text >
               <View style={{flexDirection:'column',alignItems:'center'}}>
              <TouchableOpacity
              onPress={()=>{
                if(parseInt(moment(date).format('mm'))<59)
                setDate(moment(date).add(1,'minutes'));
              else
                   setDate(moment(date).set({minutes:0}));
              }}>
              <FontAwesome5 name="chevron-up" color="white"/>
              </TouchableOpacity>
              <Text style={styles.webTime}> 
              {moment(date).format('mm')}
               </Text>
               <TouchableOpacity
              onPress={()=>{
                if(parseInt(moment(date).format('mm'))>0)
                setDate(moment(date).add(-1,'minutes'));
              else
                   setDate(moment(date).set({minutes:59}));
              }}>
              <FontAwesome5 name="chevron-down"  color="white"/>
              </TouchableOpacity>
              
               </View>
         </View>
    // onpress bi moral tudi dati show na true
     }


          </View>
         
             <View style={styles.TextSectionStyle}>
        <Text>Moj status je:</Text>
         </View>     
          <View style={styles.SectionStyle}>
          <SwitchSelector style={{flex:1}}
  initial={0}
  onPress={value => setVloga(value)}
  textColor={'#ffffff'} //'#7a44cf'
  selectedColor={'#cb1a2b'}
  buttonColor={'#ffffff'}
  borderColor={'#ffffff'}
  backgroundColor={'#1c2334'}
  /*textContainerStyle={{flexDirection:'row'}}
  textStyle={{width: 0,
    flexGrow: 1,
    flex: 1,flexShrink: 1}}
  selectedTextStyle={{width: 0,
    flexGrow: 1,
    flex: 1,flexShrink: 1}}
    */
  // height={50}
  //fontSize={45}
  hasPadding
  options={[
    { label: "Iščem", value: "Iščem" }, 
    { label: "Igram", value: "Igram" },
    { label: "Treniram", value: "Treniram"}, 
    { label: "Gledam", value: "Gledam"} 
  ]}

/>
            
          </View>
          
     
           
           
            
      
        
         <View>
          
          <TouchableOpacity
            style={[styles.buttonStyle, loading?styles.buttonDisabledStyle:null]}
            activeOpacity={0.5}
             disabled={loading}
            onPress={handleSubmitButton}>
            <Text style={styles.buttonTextStyle}>Najavi se!</Text>
          </TouchableOpacity>
          </View>
          {uspesnaNajava&&<Text style={styles.SectionStyle}>Uspešno si najavil svoj prihod na igrišče. Tukaj ga lahko spremeniš, svojo prisotnost pa vidiš pri pregledu igrišča, na katerega si se prijavil</Text>}
      
      </ScrollView>
    </View>
  
     
     
    
  );
}


const styles = StyleSheet.create({
  mainBody: {
    flex: 1,
    justifyContent: 'center',
    
    alignContent: 'center',
  },
  androidTime:{
    marginTop: 5,
    marginLeft: 35,
    marginRight: 35,
    marginBottom:10,
     textAlign: 'center',
     fontSize:24
  },
   webTime:{
    marginTop: 5,
    marginBottom:10,
     textAlign: 'center',
     fontSize:24
  },
  SectionStyle: {
    flexDirection: 'row',
  
    marginTop: 5,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
  },
  TextSectionStyle: {
    flexDirection: 'column',
    marginTop: 15,
    marginLeft: 35,
    marginRight: 35,
    margin: 5,
  },
  RadioButtonViewStyle: {
    flexDirection: 'column',
  
    marginTop: 20,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
  },
  linkStyle:{
     borderWidth: 0,
    
    textDecorationLine:"underline",
    color: '#cb1a2b',
    fontFamily :'Exo_700Bold'
  },
  buttonStyle: {
    borderWidth: 0,
    color: '#FFFFFF',
    borderColor: '#7DE24E',
    backgroundColor: '#cb1a2b',
    height: 40,
    alignItems: 'center',
    marginLeft: 35,
    marginRight: 35,
    marginTop: 20,
    marginBottom: 20,
  },
  buttonDisabledStyle: {
    opacity:0.5
  },
  buttonTextStyle: {
    color: '#FFFFFF',
    paddingVertical: 10,
    fontSize: 16,
  },
  inputStyle: {
    flex: 1,
    color: 'white',
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderColor: '#dadae8',
  },
  errorTextStyle: {
    color: 'red',
    textAlign: 'center',
    fontSize: 14,
  },
  successTextStyle: {
    color: 'white',
    textAlign: 'center',
    fontSize: 18,
    padding: 30,
  },
});
