
import { StyleSheet, TouchableOpacity,Dimensions  } from 'react-native';
import Colors from '../constants/Colors';
import { MonoText } from '../components/StyledText';
import { Text, View } from '../components/Themed';
import React, {useState, createRef} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import SwitchSelector from "react-native-switch-selector";

import Constants from 'expo-constants';
const apiServerUrl=Constants.manifest.extra.apiServerUrl;

import {
  ActivityIndicator,


  TextInput,
  Image,
  KeyboardAvoidingView,
  Keyboard,
  ScrollView,
  Picker
} from 'react-native';
import { UserContext } from "../state/User"

/*
poglej če je user_id v ls, če je, poglej, če so ostali podatki v ls 
naloži podatke in jih prepiši (če imaš net..., če ne vzami iz ls, če n v ls, izpiši napako)
Logout gumb!

ni user_id, pokaži prijavo, spodaj pa link, da če še nisi registriran, si ustvari račun
register, po register, dobiš nazaj user_id, shrani ga, in pokaži možnost urejanja profila
(spremeni geslo na mail??, kasneje)

po prijavi dodaj user_id, dobi podatke in prikaži urejanje profila

*/


export default function RegisterScreen({ path,route,navigation }: { path: string,route:any,navigation:any }) {
const [ state, dispatch ] = React.useContext(UserContext);

  const [userId, setUserId] = useState('');
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userAge, setUserAge] = useState('');
  const [vrstaIgralca, setVrstaIgralca] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errortext, setErrortext] = useState('');
  const [selectedValue, setSelectedValue] = useState("Rekreativec");
   const [current, setCurrent] = useState("Rekreativec")
  const [
    isRegistraionSuccess,
    setIsRegistraionSuccess
  ] = useState(false);

  const emailInputRef = createRef();
  const ageInputRef = createRef();
  const addressInputRef = createRef();
  const passwordInputRef = createRef();


function handleHelpPress() {
  
}
const handleSubmitButton = () =>{

 setErrortext('');
   
   /* if (!userName) {
      alert('Please fill Name');
      return;
    }
    */
    if (!userEmail) {
      setErrortext('Email in geslo sta obvezna podatka');
      return;
    }
    if (!userPassword) {
      setErrortext('Email in geslo sta obvezna podatka');
      return;
    }
     if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(userEmail))){
        setErrortext('Vpiši veljavnen email naslov!');
        return;
      }

    
    if (!(/((?=.*[0-9])|(?=.*[^A-Za-z0-9]))(?=.{6,})/.test(userPassword))){
        setErrortext('Geslo more vsebovati vsaj eno številko ali poseben znak in biti dolgo vsaj 6 znakov!');
        return;
      }

      if (!userAge.match(/^(19|20)\d{2}$/)&&userAge) {
        setErrortext('Leto rojstva mora biti število med 1900 in 2099');
      return;
  }

    var data = {
     // _id:userId,
      name: userName,
      email: userEmail,
      yofb: userAge,
      vrstaIgralca: current,
      password: userPassword,
      deliLetoRojstva:true,
      deliVrstoIgralca:true,
      deliAvatar:true,
      deliIme:true
      
    };
    

    fetch(apiServerUrl+'user/register', {
      method: 'POST',
      body: JSON.stringify({data}),
      headers: {
       Accept: 'application/json',
      'Content-Type': 'application/json'
 
      },
    })
      .then((response) =>  {
          console.log(response);
          if(response.ok)
            return response.json();
          else 
            throw new Error(response.statusText);
        })
      .then((responseJson) => {
        //Hide Loader
        setLoading(false);
        console.log(responseJson);
        // If server response message same as Data Matched
        if (responseJson._id) {
           dispatch({ type: "loadUser",user:responseJson });
           AsyncStorage.setItem('@gd_user', JSON.stringify(responseJson)).then(()=>
          {
                navigation.reset({index: 0,routes: [{ name: 'Profil'}]});
          });
          
          //navigation.reset({index: 0,routes: [{ name: 'ProfilScreen '}]});
        } else if(responseJson.napaka) {
          setErrortext(responseJson.napaka);
        }
        else
          setErrortext("Neznana napaka");
      })
      .catch((error) => {
        //Hide Loader
        setLoading(false);
        console.log(error);
        setErrortext("Napaka pri shranjevanju podatkov!");
      });





}
/*
 <View style={{alignItems: 'center'}}>
          <Image
            source={require('../assets/images/goran-dragic-logo.png')}
            style={{
              width: '90%',
              height: 100,
              resizeMode: 'contain',
              margin: 1,
            }}
          />
        </View>
*/

  return (
    
      <View style={{flex: 1}}>
    
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
          justifyContent: 'center',
          alignContent: 'center',
        }}>
          {errortext != '' ? (
          <View style={styles.SectionStyle}>
          
            <Text style={styles.errorTextStyle}>
              {errortext}
            </Text>
          </View>
          ) : null}
        <View style={styles.SectionStyle}>
        <Text >
        Če želite uporabljati lastni profil v aplikaciji, se morate najprej registrirati.
        </Text>
        </View>
        <KeyboardAvoidingView enabled>
          <View style={styles.SectionStyle}>
            <TextInput
              style={styles.inputStyle}
              onChangeText={(UserEmail) => setUserEmail(UserEmail)}
              underlineColorAndroid="#f000"
              placeholder="Vnesi Email"
              placeholderTextColor="#8b9cb5"
              keyboardType="email-address"
              ref={emailInputRef}
              returnKeyType="next"
              onSubmitEditing={() =>
                passwordInputRef.current &&
                passwordInputRef.current.focus()
              }
              blurOnSubmit={false}
            />
          </View>
          <View style={styles.SectionStyle}>
            <TextInput
              style={styles.inputStyle}
              onChangeText={(UserPassword) =>
                setUserPassword(UserPassword)
              }
              underlineColorAndroid="#f000"
              placeholder="Vnesi geslo"
              placeholderTextColor="#8b9cb5"
              ref={passwordInputRef}
              returnKeyType="next"
              secureTextEntry={true}
              onSubmitEditing={() =>
                ageInputRef.current &&
                ageInputRef.current.focus()
              }
              blurOnSubmit={false}
            />
          </View>
          <View style={styles.SectionStyle}>
            <TextInput
              style={styles.inputStyle}
              onChangeText={(UserName) => setUserName(UserName)}
              underlineColorAndroid="#f000"
              placeholder="Vnesi ime"
              placeholderTextColor="#8b9cb5"
              autoCapitalize="sentences"
              returnKeyType="next"
              onSubmitEditing={() =>
                emailInputRef.current && emailInputRef.current.focus()
              }
              blurOnSubmit={false}
            />
          </View>
          <View style={styles.SectionStyle}>
            <TextInput
              style={styles.inputStyle}
              onChangeText={(UserAge) => setUserAge(UserAge)}
              underlineColorAndroid="#f000"
              placeholder="Vnesi leto rojstva"
              placeholderTextColor="#8b9cb5"
              keyboardType="numeric"
              ref={ageInputRef}
              returnKeyType="next"
              onSubmitEditing={() =>
                addressInputRef.current &&
                addressInputRef.current.focus()
              }
              blurOnSubmit={false}
            />
          </View>
          <View style={styles.SectionStyle}>
          <Text>Vrsta igralca:</Text>
          </View>
            <View style={styles.SectionStyle}>
           <SwitchSelector style={{flex:1}}
  initial={0}
  onPress={value => setCurrent(value)}
  textColor={'#ffffff'} //'#7a44cf'
  selectedColor={'#cb1a2b'}
  buttonColor={'#ffffff'}
  borderColor={'#ffffff'}
  backgroundColor={'#1c2334'}
  hasPadding
  options={[
    { label: "Rekreativec", value: "Rekreativec" }, 
    { label: "Amater", value: "Amater" },
    { label: "Profesionalec", value: "Profesionalec"}
  ]}

/>

          
            
          </View>
         
          <View style={styles.SectionStyle}> 
          <TouchableOpacity
            style={styles.buttonStyle}
            activeOpacity={0.5}
            onPress={handleSubmitButton}>
            <Text style={styles.buttonTextStyle}>Registriraj se</Text>
          </TouchableOpacity>
          </View>
        </KeyboardAvoidingView>
      </ScrollView>
    </View>
  
     
     
    
  );
}


const styles = StyleSheet.create({
  SectionStyle: {
    flexDirection: 'row',
    
    marginTop: 10,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
  },
  RadioButtonViewStyle: {
    flexDirection: 'column',
  
    marginTop: 20,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
  },
  buttonStyle: {
    flex:1,
    borderWidth: 0,
    color: '#FFFFFF',
    borderColor: '#7DE24E',
    backgroundColor: '#cb1a2b',
    height: 40,
    alignItems: 'center',
 
  },

  buttonDisabledStyle: {
    opacity:0.5
  },
  buttonTextStyle: {
    color: '#FFFFFF',
    paddingVertical: 10,
    fontSize: 16,
  },
  inputStyle: {
    height: 40,
    flex: 1,
    color: 'white',
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderColor: '#dadae8',
  },
  errorTextStyle: {
    color: 'red',
    textAlign: 'center',
    fontSize: 14,
  },
  successTextStyle: {
    color: 'white',
    textAlign: 'center',
    fontSize: 18,
    padding: 30,
  },
});
