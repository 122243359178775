



import React, { useContext, useEffect,useState } from 'react';
import { StyleSheet,Image,Dimensions,ScrollView,TouchableOpacity } from 'react-native';

import Igraj from '../components/Igraj';
import { Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import { UserContext } from "../state/User"
import Constants from 'expo-constants';
const apiServerUrl=Constants.manifest.extra.apiServerUrl;
import { Linking} from 'react-native'


export default function SponzorjiScreen({navigation,route}) {
   const colorScheme = useColorScheme();
   const user=null;
     const screenWidth = Dimensions.get('window').width;
     const screenHeight = Dimensions.get('window').width;
     const [sponzorji, setSponzorji] = useState([]);
    const [ state, dispatch ] = React.useContext(UserContext);
    console.log("state");
   console.log(state);



   
const loadSponzorji= () => {
   

    fetch(`${apiServerUrl}sponzorji`, {
      method: 'GET',
      headers: {
       Accept: 'application/json',
      'Content-Type': 'application/json'
 
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        //Hide Loader
        console.log(responseJson);
        // če prijava uspe, pelji tam kjer si prišel iz prijave ozirma na registracijske podatke zaenkrat
        if (responseJson.napaka) {
        } 
        else {
         setSponzorji(responseJson);
          
        }
      })
      .catch((error) => {
        //dodaj na treniraj seznam še error, če se ne naložijo videi!!!
        console.error(error);
      });
   }
    

useEffect(() => {
  loadSponzorji();
}, []);








  return (
        <View style={styles.container}>
    <ScrollView contentContainerStyle={{
          justifyContent: 'center',
          alignContent: 'center',
        }}>

             <View style={styles.SectionStyle}>
      <Text style={styles.text}>
       Projekt ne bi bil mogoč brez podpore sponzorjev in partnerjev projekta, katerim se iskreno zahvajujemo!
      </Text>

      </View>

     {
    sponzorji.map((s, i) => (
      <TouchableOpacity key={i}   onPress={() => {
            console.log(s);
              if(s.link)
                Linking.openURL(s.link);
            }}>
      <View style={styles.SponsorSectionStyle}  >
      <Image source={{uri:s.url}} style={{ width:(screenWidth-70), height:(screenHeight*0.35)}}
       resizeMode={"contain"}
    

      />
      </View>
      </TouchableOpacity>
      ))}

    </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    
  },
  SponsorSectionStyle: {
    flexDirection: 'row',
    marginTop: 10,
    marginLeft: 35,
    marginRight: 35,
    
    alignItems:"center",justifyContent:"center",
    //backgroundColor:"white"

        
  },
  SectionStyle: {
    flexDirection: 'row',
    marginTop: 10,
    marginLeft: 35,
    marginRight: 35,

        
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  text:{
    textAlign:"justify",
    marginTop:10
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
