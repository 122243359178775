import * as React from 'react';
import { StyleSheet,Image,Dimensions,TouchableOpacity,ImageBackground,ScrollView } from 'react-native';

//import SplashScreen from '../components/SplashScreen';
import { Text, View } from '../components/Themed';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import logo from '../assets/images/goran-dragic-logo.png';

import igraj from '../assets/images/igraj.png';
import oprojektu from '../assets/images/oprojektu.png';
import kdoigra from '../assets/images/kdoigra.png';
import treniraj from '../assets/images/treniraj.png';
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const guidelineBaseWidth = 350;
const guidelineBaseHeight = 680;

const scale = size => windowWidth / guidelineBaseWidth * size;
const verticalScale = size => windowHeight / guidelineBaseHeight * size;
const moderateScale = (size, factor = 0.5) => size + ( scale(size) - size ) * factor;

import Constants from 'expo-constants';
const apiServerUrl=Constants.manifest.extra.apiServerUrl;



export default function DomovScreen({navigation}) {
const [errortext, setErrortext] = React.useState('');
const video = React.useRef(null);
  //const [status, setStatus] = React.useState({});
/*
 <View style={styles.getStartedContainer}>
      
        <Text
          style={styles.getStartedText}
          darkColor="rgba(0,0,0,0.8)"
          lightColor="rgba(255,255,255,0.8)">
         Uvodno besedilo v app, v smislu Gogi te vabi, da prideš na njegovo igrišče in narediš nekaj za sebe/se družiš
        </Text>
</View>
*/



const handleSmartCourtPressKdoIgra = () =>{
   


    fetch(apiServerUrl+'igrisce', {
      method: 'GET',
      headers: {
       Accept: 'application/json',
      'Content-Type': 'application/json'
 
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        //Hide Loader
        console.log(responseJson);
        // če prijava uspe, pelji tam kjer si prišel iz prijave ozirma na registracijske podatke zaenkrat
        if (responseJson.napaka) {
        } 
        else {
        console.log(    responseJson          );
        navigation.navigate('Igrisca',{list:responseJson,title:"Pametna igrišča"});
         
       
          
        }
      })
      .catch((error) => {
        //dodaj na treniraj seznam še error, če se ne naložijo videi!!!
        setErrortext("Napaka pri vzpostavitvi povezave s strežnikom!");
        console.error(error);
      });
}
const handleSmartCourtPressTrenirajTreniraj = () =>{

  navigation.navigate('Treningi',{title:"Treningi"});
/*
    fetch(apiServerUrl+'video/vaje', {
      method: 'GET',
      headers: {
       Accept: 'application/json',
      'Content-Type': 'application/json'
 
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        //Hide Loader
        console.log(responseJson);
        // če prijava uspe, pelji tam kjer si prišel iz prijave ozirma na registracijske podatke zaenkrat
        if (responseJson.napaka) {
        } 
        else {
        console.log(    responseJson          );
        navigation.navigate('Videi',{list:responseJson,title:"Treningi"});
         
       
          
        }
      })
      .catch((error) => {
        //dodaj na treniraj seznam še error, če se ne naložijo videi!!!
        console.error(error);
        setErrortext("Napaka pri vzpostavitvi povezave s strežnikom!");
      });

 */
}
const handleSmartCourtPressKosarkarskeIgre = () =>{
   


    fetch(apiServerUrl+'video/igre', {
      method: 'GET',
      headers: {
       Accept: 'application/json',
      'Content-Type': 'application/json'
 
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        //Hide Loader
        console.log(responseJson);
        // če prijava uspe, pelji tam kjer si prišel iz prijave ozirma na registracijske podatke zaenkrat
        if (responseJson.napaka) {
        } 
        else {
        console.log(    responseJson          );
        navigation.navigate('Videi',{list:responseJson,title:"Družabne igre"});
         
       
          
        }
      })
      .catch((error) => {
        //dodaj na treniraj seznam še error, če se ne naložijo videi!!!
        console.error(error);
        setErrortext("Napaka pri vzpostavitvi povezave s strežnikom!");
      });
}
const handleSmartCourtPressOProjektu = () =>{
   navigation.navigate('OProjektu')
}

    const colorScheme = useColorScheme();
  return (
    <View style={styles.container}>

    <View style={styles.imageContainer}>
    <Image source={logo} style={styles.logo} resizeMode="contain" />
    </View>
      
     {(errortext) != '' ? (
                <View style={styles.SectionStyle}>
            <Text style={styles.errorTextStyle}>
              {errortext}
            </Text>

            </View>
          ) : null}
<View style={styles.afterLogoScreen}>  
<ScrollView>    
<View style={styles.domovActionButtonWrapper}>      
       <TouchableOpacity
            style={styles.domovActionButton}
            activeOpacity={0.5}
            onPress={handleSmartCourtPressKdoIgra}>
         
            <Image source={kdoigra} resizeMode={"contain"} style={styles.buttonImage}/>
           
            <Text style={styles.buttonText}>Kdo igra?</Text>
          
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.domovActionButton}
            activeOpacity={0.5}
            onPress={handleSmartCourtPressTrenirajTreniraj}>
              <Image source={treniraj} resizeMode={"contain"} style={styles.buttonImage}/>
            <Text style={styles.buttonText}>Treniraj!</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.domovActionButton}
            activeOpacity={0.5}
            onPress={handleSmartCourtPressKosarkarskeIgre}>
              <Image source={igraj} resizeMode={"contain"} style={styles.buttonImage}/>
            <Text style={styles.buttonText}>Družabne igre</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.domovActionButton}
            activeOpacity={0.5}
            onPress={handleSmartCourtPressOProjektu}>
              <Image source={oprojektu} resizeMode={"contain"} style={styles.buttonImage}/>
            <Text style={styles.buttonText}>O projektu</Text>
          </TouchableOpacity>
          </View>
          </ScrollView>
    </View>

    </View>
  );
}
//<View style={styles.separator} lightColor={Colors[colorScheme].tint}  darkColor={Colors[colorScheme].tint} />

const styles = StyleSheet.create({
  logo:{
    
    width:Dimensions.get('window').width-70,
    height:100
    
  },
   imageContainer:{
    
    marginVertical: 15,
    marginLeft:35,
    marginRight:35


  },
   SectionStyle: {
    flexDirection: 'row',
    marginTop: 10,
    marginLeft: 35,
    marginRight: 35,

        
  },
    errorTextStyle: {
    color: 'red',
    textAlign: 'center',
    fontSize: 14,
  },
 
   buttonImage:{
    width:Dimensions.get('window').width*0.3,
    height:Dimensions.get('window').width*0.3,
    marginBottom:verticalScale(10)
  },
  buttonText:{
    color:'#ffffff',
    fontFamily:'Exo_700Bold',
    textTransform:'uppercase',
    fontSize:verticalScale(13)
  },
  domovActionButton:{
   width:(Dimensions.get('window').width/2-verticalScale(30)),
   height:(Dimensions.get('window').width/2-verticalScale(30)),
    borderColor:'#ffffff',
    //backgroundColor:'#ffffff',
    borderWidth: 1,
    margin: verticalScale(10),
    alignItems:'center',
    justifyContent:'space-evenly'

  },
  afterLogoScreen:{
    flex:1,
    alignItems:'flex-start',
    justifyContent:'center'

  },
  domovActionButtonWrapper:{
  
  display:'flex',
  flexWrap:'wrap',
  flexDirection:'row',
   alignItems:'center',
    justifyContent:'center'
  

  }
  ,
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
  
    height: 1,
    width: '80%',

  },
   getStartedContainer: {
   
    flexDirection:'column',
    justifyContent:"space-between",
    alignItems: 'center',
    marginHorizontal: 50,
  },
   
  homeScreenFilename: {
    marginVertical: 7,
  },
  codeHighlightContainer: {
    borderRadius: 3,
    paddingHorizontal: 4,
  },
  getStartedText: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  helpContainer: {
    marginTop: 15,
    marginHorizontal: 20,
    alignItems: 'center',
  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    textAlign: 'center',
  },
  video: {
    alignSelf: 'center',
   
    width: Dimensions.get('window').width,
    
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
