
import { StyleSheet, TouchableOpacity,Dimensions  } from 'react-native';
import Colors from '../constants/Colors';
import { MonoText } from '../components/StyledText';
import { Text, View } from '../components/Themed';
import React, {useState, createRef} from 'react';
import RadioButton from 'expo-radio-button'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { UserContext } from "../state/User"

import Constants from 'expo-constants';
const apiServerUrl=Constants.manifest.extra.apiServerUrl;

//import Loader from '../components/Loader';

import {
  ActivityIndicator,


  TextInput,
  Image,
  KeyboardAvoidingView,
  Keyboard,
  ScrollView,
  Picker
} from 'react-native';


/*
poglej če je user_id v ls, če je, poglej, če so ostali podatki v ls 
naloži podatke in jih prepiši (če imaš net..., če ne vzami iz ls, če n v ls, izpiši napako)
Logout gumb gre v urejanje profila!

ni user_id, pokaži prijavo, spodaj pa link, da če še nisi registriran, si ustvari račun
register, po register, dobiš nazaj user_id, shrani ga, in pokaži možnost urejanja profila
(spremeni geslo na mail??, kasneje)

po prijavi dodaj user_id, dobi podatke in prikaži urejanje profila

*/


export default function PrijavaScreen({ navigation,route}) {
  
  const [userId, setUserId] = useState('');
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userAge, setUserAge] = useState('');
  const [vrstaIgralca, setVrstaIgralca] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errortext, setErrortext] = useState('');
  const [selectedValue, setSelectedValue] = useState("rekreativec");
   const [current, setCurrent] = useState("rekreativec")
  const [
    isRegistraionSuccess,
    setIsRegistraionSuccess
  ] = useState(false);

  const emailInputRef = createRef();
  const ageInputRef = createRef();
  const addressInputRef = createRef();
  const passwordInputRef = createRef();
  const [ state, dispatch ] = React.useContext(UserContext);

 /*AsyncStorage.getItem('@gd_user').then((value) =>
        {
          //preberi še ostale podatke
          console.log("imam id:" + value);
          if(value){
            navigation.reset({index: 0,routes: [{ name: 'ProfilScreen', params: { user: JSON.parse(value) }}]});

          }
        }
 );
*/
//if(!state.user.niPrijavljen)
  //navigation.reset({index: 0,routes: [{ name: 'ProfilScreen'}]});  

function handleHelpPress() {
  
}

const handleRegisterButton = () =>{
   navigation.navigate('Registracija')
}

const handleSubmitButton = () =>{

 //setErrortext('');
   
   
    if (!userEmail) {
      setErrortext("Prosim vnesite vaš elektronski naslov!");
      return;
    }
    
    if (!userPassword) {
     setErrortext("Geslo ne sme biti prazno!");
      return;
    }



    var data = {
      //id:userId,
      //name: userName,
      email: userEmail,
      //yofb: userAge,
      //vrstaIgralca: vrstaIgralca,
      password: userPassword,
      
    };
    setLoading(true);

    fetch(apiServerUrl+'user/login', {
      method: 'POST',
      body: JSON.stringify({data}),
      headers: {
       Accept: 'application/json',
      'Content-Type': 'application/json'
 
      },
    })
      .then((response) => {
          console.log(response);
          if(response.ok)
            return response.json();
          else 
            throw new Error(response.statusText);
        })
      .then((responseJson) => {
        //Hide Loader
        setLoading(false);
        console.log(responseJson);
        // če prijava uspe, pelji tam kjer si prišel iz prijave ozirma na registracijske podatke zaenkrat
        if (responseJson.napaka) {
          setErrortext(responseJson.napaka);
          
        } else {
          console.log(responseJson);
          dispatch({ type: "loadUser",user:responseJson });

          AsyncStorage.setItem('@gd_user', JSON.stringify(responseJson)).then(()=>
          {
                  navigation.reset({index: 0,routes: [{ name: 'Profil'}]});
          });
          

       
          
        }
      })
      .catch((error) => {
        //Hide Loader
      
          console.error(error);
        setLoading(false);
        setErrortext("Napaka pri prijavi!");
      });





}


  return (
    
      <View style={styles.mainBody}>
     
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
            flex: 1,
          justifyContent: 'flex-start',
          alignContent: 'center',
        }}>
          {errortext != '' ? (
          <View style={styles.SectionStyle}>
        
            <Text style={styles.errorTextStyle}>
              {errortext}
            </Text>
             </View>
          ) : null}
        <View style={styles.SectionStyle}>
        {route.params&&route.params.msg&&<Text >
        {route.params.msg}
        </Text>}
        <Text >
        Prijavite se z vašimi podatki:
        </Text>
        </View>
        <KeyboardAvoidingView enabled>
          
          <View style={styles.SectionStyle}>
            <TextInput
              style={styles.inputStyle}
              onChangeText={(UserEmail) => setUserEmail(UserEmail)}
              underlineColorAndroid="#f000"
              placeholder="Vnesi e-mail"
              placeholderTextColor="#8b9cb5"
              keyboardType="email-address"
              ref={emailInputRef}
              returnKeyType="next"
              onSubmitEditing={() =>
                passwordInputRef.current &&
                passwordInputRef.current.focus()
              }
              blurOnSubmit={false}
            />
          </View>
          <View style={styles.SectionStyle}>
            <TextInput
              style={styles.inputStyle}
              onChangeText={(UserPassword) =>
                setUserPassword(UserPassword)
              }
              underlineColorAndroid="#f000"
              placeholder="Vnesi geslo"
              placeholderTextColor="#8b9cb5"
              ref={passwordInputRef}
              returnKeyType="next"
              secureTextEntry={true}
              
              blurOnSubmit={false}
            />
          </View>
          
     
           
           
            
           

             <View style={styles.SectionStyle}>
          <TouchableOpacity
           style={[styles.buttonStyle, loading?styles.buttonDisabledStyle:null]}
            activeOpacity={0.5}
            onPress={handleSubmitButton}
            disabled={loading}
            >
            <Text style={styles.buttonTextStyle}>Prijava</Text>
          </TouchableOpacity>
          </View>
            <View style={styles.SectionStyle}>
        <Text >
        Če še nimate računa, si ga ustvarite:
        </Text>
         </View>
         <View style={styles.SectionStyle}>
          
          <TouchableOpacity
            style={[styles.buttonStyle, loading?styles.buttonDisabledStyle:null]}
            activeOpacity={0.5}
             disabled={loading}
            onPress={handleRegisterButton}>
            <Text style={styles.buttonTextStyle}>Registracija</Text>
          </TouchableOpacity>
          </View>
        </KeyboardAvoidingView>
      </ScrollView>
    </View>
  
     
     
    
  );
}


const styles = StyleSheet.create({
  mainBody: {
    flex: 1,
    justifyContent: 'flex-start',
    
    alignContent: 'center',
  },
  SectionStyle: {
    flexDirection: 'row',
   
    marginTop: 10,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
  },
  TextSectionStyle: {
    flexDirection: 'row',
    marginTop: 5,
    marginLeft: 35,
    marginRight: 35,
    margin: 5,
  },
  RadioButtonViewStyle: {
    flexDirection: 'column',
  
    marginTop: 20,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
  },
  buttonStyle: {
    flex:1,
    borderWidth: 0,
    color: '#FFFFFF',
    borderColor: '#7DE24E',
    backgroundColor: '#cb1a2b',
    height: 40,
    alignItems: 'center',
   
  },
  buttonDisabledStyle: {
    opacity:0.5
  },
  buttonTextStyle: {
    color: '#FFFFFF',
    paddingVertical: 10,
    fontSize: 16,
  },
  inputStyle: {
    flex: 1,
     height: 40,
    color: 'white',
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderColor: '#dadae8',
  },
  errorTextStyle: {
    color: 'red',
    textAlign: 'center',
    fontSize: 14,
  },
  successTextStyle: {
    color: 'white',
    textAlign: 'center',
    fontSize: 18,
    padding: 30,
  },
});
