import { StatusBar } from 'expo-status-bar';
import React,{useState} from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';
import { UserProvider } from "./state/User"

export default function App() {
  const colorScheme = useColorScheme();


  const [isLoadingComplete,user] = useCachedResources();
  

    //preveri prijavo, naredi svoj bool za nalaganje prijave

    /*AsyncStorage.getItem('@gd_user').then((value) =>
        {
          //preberi še ostale podatke
          console.log("imam id:" + value);
          if(value){
             //navigation.replace('ProfilScreen',{user:JSON.parse(value)});
             //če nisi prijavljen, se boš v igrišče prijavil anonimno, izpiši nek msg
             setJePrijavljen(true);
          }
        }

 );
*/

if(!isLoadingComplete)
  return null
else
   return (
    <UserProvider initialState={{user:user}}>
     <SafeAreaProvider>
        <Navigation colorScheme={colorScheme} />
        <StatusBar />
      </SafeAreaProvider>
      </UserProvider>
  );
  /*

  return (
     <UserProvider>
     <SafeAreaProvider>
        <Navigation colorScheme={colorScheme} />
        <StatusBar />
      </SafeAreaProvider>
      </UserProvider>
      )
      */
}
