



import React, { useContext, useEffect } from 'react';
import { StyleSheet,Image,Dimensions,ScrollView } from 'react-native';

import Igraj from '../components/Igraj';
import { Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import { UserContext } from "../state/User"
import igrisce from '../assets/images/igrisce.jpg';
import maloigrisce from '../assets/images/maloigrisce.jpg';
import tribuna from '../assets/images/tribuna.jpg';


export default function PodamdaigramScreen({navigation,route}) {
   const colorScheme = useColorScheme();
   const user=null;
     const screenWidth = Dimensions.get('window').width;
     const screenHeight = Dimensions.get('window').width;
    const [ state, dispatch ] = React.useContext(UserContext);
    console.log("state");
   console.log(state);
   
  return (
        <View style={styles.container}>
    <ScrollView contentContainerStyle={{
          justifyContent: 'center',
          alignContent: 'center',
        }}>

             <View style={styles.SectionStyle}>
      <Text style={styles.text}>
        Igrišče Goran Dragič sestavljata glavno veliko igrišče ter manjše stransko igrišče.
      </Text>
      </View>
      <View style={styles.SectionStyle}>
      <Image source={igrisce} style={{ width:(screenWidth-70), height:(screenWidth-70)*0.4648}} resizeMode={"contain"}/>
      </View>
      
            <View style={styles.SectionStyle}>
      <Text style={styles.text}>
       Glavno veliko igrišče je opremljeno s sodobno podlago iz umetne mase, ki zagotavlja dober oprijem, dobro blaženje in se v primeru dežja tudi zelo hitro suši. Je odporno na vremenske pogoje, mehanske poškodbe in je zaradi svoje modularnosti tudi enostavno za vzdrževanje, saj se poškodovani deli lahko zamenjajo.  
      </Text>
      </View>
        <View style={styles.SectionStyle}>
      <Image source={maloigrisce} style={{ width:(screenWidth-70), height:(screenWidth-70)*0.4648}} resizeMode={"contain"}/>
      </View>
            <View style={styles.SectionStyle}>
      <Text style={styles.text}>
      Manjše stransko igrišče vsebuje dva koša različnih višin in je primerno predvsem za mlajše košarkarje. Na tleh so dodane označbe za atletske vaje, igrišče pa je prav tako namenjeno različnim družabnim igram. Slednje vsebine so dostopne preko mobilne aplikacije, v kateri se nahajate.
      </Text>
      </View>
           <View style={styles.SectionStyle}>
      <Image source={tribuna} style={{ width:(screenWidth-70), height:(screenWidth-70)*0.4648}} resizeMode={"contain"}/>
      </View>
            <View style={styles.SectionStyle}>
      <Text style={styles.text}>
      Ob igrišču najdemo še veliko prenovljeno tribuno ter informacije o samem projektu #PODAMDAIGRAM.
      </Text>

      </View>

    </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    
  },
  SectionStyle: {
    flexDirection: 'row',
    marginTop: 10,
    marginLeft: 35,
    marginRight: 35,

        
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  text:{
    textAlign:"justify",
    marginTop:10
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
