import React, { useContext, useEffect } from 'react';
import { StyleSheet,Image,Dimensions,ScrollView } from 'react-native';

import Igraj from '../components/Igraj';
import { Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import { UserContext } from "../state/User"
import podpis from '../assets/images/podpis.png';
export default function PodamdaigramScreen({navigation,route}) {
   const colorScheme = useColorScheme();
   const user=null;
    const [ state, dispatch ] = React.useContext(UserContext);
    console.log("state");
   console.log(state);
      const screenWidth = Dimensions.get('window').width;
  return (
    <View style={styles.container}>
  <ScrollView contentContainerStyle={{
          justifyContent: 'center',
          alignContent: 'center',
        }}>
      <View style={styles.SectionStyle}>
      <Text style={styles.text}>
        Pri prenovi zunanjih košarkarskih igrišč ter preoblikovanju le-teh v pametna igrišča #PODAMDAIGRAM gre za skupno akcijo Fundacije Goran Dragić ter njenih sponzorjev in partnerjev.
      </Text>
      </View>
      <View style={styles.SectionStyle}>
      <Text style={styles.text}>
       Potem ko je bilo v letu 2019 odprto prvo igrišče v ljubljanskem Tivoliju, je leta 2021 luč sveta ugledal čudovit zunanji kompleks na mariborskem Taboru.
      </Text>
      </View>
      <View style={styles.SectionStyle}>
      <Text style={styles.text}>
      Igrišča #PODAMDAIGRAM sledijo pozitivni, združevalni in plemeniti miselnosti glavnega protagonista projekta, enega najboljših slovenskih košarkarjev vseh časov, Gorana Dragića. Ta se bo skupaj s svojo fundacijo in njenimi podporniki tudi v prihodnje trudil zbirati sredstva ter jih porabiti za prenovo novih zunanjih košarkarskih igrišč po celotni Sloveniji.</Text>
    </View>
    <View style={styles.SectionStyle}>
      <Text style={styles.text}>
       »Želim vam, da se imate na igrišču lepo. Da drug drugega spoštujete, četudi ste tekmovalni. Da imate posluh za drugačne. Da ste strpni. Da vas vodi »fair play« ter da skrbite, da bo to okolje za vse varno, prijazno in čisto. Pa čim več podaj in zadetih košev!«
     </Text>
     </View>
    <View style={styles.SectionStyle}>
      <Text style={styles.text}>
    Vaš Gogi     
     </Text>
     <Image source={podpis} style={{ width:(screenWidth-70)/3, height:(screenWidth-70)/3*0.4648}} resizeMode={"contain"}/>
     </View>
  

</ScrollView>
    
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    
  },
  SectionStyle: {
    flexDirection: 'row',
    marginVertical: 5,
    marginLeft: 35,
    marginRight: 35,
    justifyContent:"space-between"

        
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  text:{
    marginTop:10,
    textAlign:"justify"
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
