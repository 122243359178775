import * as React from 'react';
import { StyleSheet,ScrollView,Dimensions } from 'react-native';
import {useState, createRef,useEffect} from 'react';
//import Igraj from '../components/Igraj';
import { Text, View } from '../components/Themed';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

//import Vimeo from '@u-wave/react-vimeo';
//import VimeoPlayer from '../components/VimeoPlayer';
//import { WebView } from 'react-native-webview';
//import { VimeoPlayer } from '@mindtechapps/rn-vimeo-player';
import { Video, AVPlaybackStatus } from 'expo-av';
import Constants from 'expo-constants';
const apiServerUrl=Constants.manifest.extra.apiServerUrl;

/*
<Vimeo
  video={video.url}
  autoplay
  width={windowWidth}
  
/>

<VimeoPlayer videoUrl=""/>
<VimeoPlayer videoId="565205582"
    style="{}" />

*/
export default function TreningiScreen({ route,navigation }) {
   const colorScheme = useColorScheme();
   const video=route.params.video;
    const videoPlayer = React.useRef(null);
    const [isLoading, setIsLoading] = useState(true);
     const [shouldPlay, setShouldPlay] = useState(true);
     const windowWidth = Dimensions.get('window').width;
    const [vimeoUrl, setVimeoUrl] = useState();
    const [vimeoWidth, setVimeoWidth] = useState(windowWidth);
    const [vimeoHeight, setVimeoHeight] = useState(windowWidth*0.5625);



 useEffect(() => {
    const unsubscribe = navigation.addListener('blur', (e) => {
     //  setShouldPlay(false);
       //console.log(videoPlayer);
       ////await videoPlayer.current.stopAsync();
       //await videoPlayer.current.presentFullscreenPlayer();
         videoPlayer.current.pauseAsync().then(()=>{
          console.log("Pa še unload");
          console.log(videoPlayer);
         // videoPlayer.current.props.source=null;
       //   navigation.dispatch(e.data.action)  
          
          
         // 
         });
       
      console.log("Stop and unload the video!!!");
        //e.preventDefault();

    });

    


    try{
  videoid=video.url.match(/\d+$/)[0];
  //var configUrl=`https://player.vimeo.com/video/${videoid}/config`;
  var configUrl=`${apiServerUrl}video/vimeo/${videoid}`;

  fetch(configUrl, {
      method: 'GET',
     headers: {
       Accept: 'application/json',
      'Content-Type': 'application/json'
 
      },
      
    })
      .then((response) => response.json())
      .then((responseJson) => {
        //Hide Loader
        console.log(responseJson);
        // če prijava uspe, pelji tam kjer si prišel iz prijave ozirma na registracijske podatke zaenkrat
        if (responseJson.napaka) {
        } 
        else {
        console.log(    responseJson          );
        /*let progressive=responseJson.request.files.progressive;
        let bestMatch={};
        let minWidthDistance=1000;
        for (let p of progressive) {
          bestMatch=p;
          if(p.quality=='720p')
           {
                bestMatch=p;
                break;

           }

        }
        console.log(bestMatch);
        */
        let bestMatch=responseJson;
        setVimeoUrl(bestMatch.url);
        setVimeoWidth(windowWidth);
        setVimeoHeight((windowWidth/bestMatch.width)*bestMatch.height);
        setShouldPlay(true);
       // navigation.navigate('Igrisca',{list:responseJson,title:"Pametna igrišča"});

       //setList(responseJson);
       setIsLoading(false);

         
       
          
        }
      })
      .catch((error) => {
        //dodaj na treniraj seznam še error, če se ne naložijo videi!!!
        console.error(error);
        setIsLoading(false);
      });
}

catch(err){
  setIsLoading(false);

}


    return unsubscribe;
  }, [navigation,videoPlayer,setShouldPlay]);
 

 let videoid=0;
 


  return (
    <View style={styles.container}>
    <Video
        ref={videoPlayer}
        style={{width:vimeoWidth,height:vimeoHeight}}
        source={{uri: vimeoUrl}}
        isMuted={false} 
        shouldPlay={shouldPlay}
        useNativeControls
        resizeMode="contain"
        onLoad={() => {videoPlayer.current.playAsync()}}
        
      />

  
<View style={styles.opisVaje}>
<Text>
{video.opis}
</Text>
</View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  videoWebView:{
    width:Dimensions.get('window').width
  },
  opisVaje: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft:35,
    marginRight:35,
    marginTop:10
  },
  video: {
    
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  backgroundVideo: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
});
