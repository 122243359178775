import uuid from 'react-native-uuid';

export const reducer = (state, action) => {
  console.log("REDUCER CALLED WITH:");
  console.log(action);
  console.log(state);
  switch (action.type) {
    case "loadUser":
      return {
        ...state,
        user: action.user
      }
      break;
      case "logoffUser":
      return {
        ...state,
        user: {"niPrijavljen":true,"name":"Anonimnež",_id:uuid.v4()}
      }
      break;

    default:
      return state
      break;
  }
}
