import { Ionicons } from '@expo/vector-icons';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import React,{ useContext, useEffect } from 'react';
import { useFonts, Exo_400Regular, Exo_700Bold, } from '@expo-google-fonts/exo';
import AsyncStorage from '@react-native-async-storage/async-storage';
//import { UserContext } from "../state/User"
import uuid from 'react-native-uuid';
import Constants from 'expo-constants';
const apiServerUrl=Constants.manifest.extra.apiServerUrl;

export default function useCachedResources() {
//    const [{ user }, dispatch] = useStateValue();
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);
 const [user, setUser] = React.useState({"niPrijavljen":true,"name":"Anonimnež"});
  //const [ state, dispatch ] = React.useContext(UserContext)
  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync();






        // Load fonts
        await Font.loadAsync({
          ...Ionicons.font,
          'space-mono': require('../assets/fonts/SpaceMono-Regular.ttf'),
        });
         await Font.loadAsync({Exo_400Regular,Exo_700Bold});

         let us= await AsyncStorage.getItem('@gd_user');
         let u=JSON.parse(us);
         console.log(u);
            if(u) {
              setUser(u);
              //fetch user from api
              fetch(`${apiServerUrl}user/loginById/${u._id}`, {
                    method: 'GET',
                    headers: {
                     Accept: 'application/json',
                    'Content-Type': 'application/json'
               
                    },
                  })
                  .then((response) => {
                      console.log(response);
                      if(response.ok)
                        return response.json();
                      else 
                        throw new Error(response.statusText);
                    })
                  .then((responseJson) => {
                  
                    console.log(responseJson);
                    if (responseJson.napaka) {
                    } else {
                     console.log(responseJson);
                     setUser(responseJson);
                     AsyncStorage.setItem('@gd_user', JSON.stringify(responseJson));
                    }
                  })
                  .catch((error) => {
                    console.log(error);

                  });
            }
            else{
              user._id= uuid.v4();;
              setUser(user);
             }
           

      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        //SplashScreen.hideAsync();
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return [isLoadingComplete,user];
}
